// src/admin/HostelPreview.js
import React, { useEffect, useState } from 'react';

function HostelPreview({
  hostelName,
  cityName,
  contact,
  notes,
  link,
  existingImages = [],
  newFiles = [],
}) {
  // All images combined: old URLs + local object URLs
  const [validImages, setValidImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Convert the newly selected files into object URLs
    const newImageUrls = newFiles.map((file) => URL.createObjectURL(file));

    // Merge existing (Firestore) image URLs + local file URLs
    const combined = [...existingImages, ...newImageUrls];
    setValidImages(combined);

    // Cleanup object URLs on unmount
    return () => {
      newImageUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [existingImages, newFiles]);

  const totalImages = validImages.length;

  const navigateImages = (direction) => {
    if (totalImages <= 1) return;
    setCurrentImageIndex((prev) => {
      if (direction === 'next') {
        return prev === totalImages - 1 ? 0 : prev + 1;
      }
      return prev === 0 ? totalImages - 1 : prev - 1;
    });
  };

  return (
    <div style={{ maxWidth: '300px', margin: '0 auto' }}>
      <h2>{hostelName}</h2>
      <p>
        <strong>Contact:</strong> {contact || 'Not provided'}
      </p>
      <p>
        <strong>City:</strong> {cityName || 'Not specified'}
      </p>
      <p>
        <strong>Notes:</strong> {notes || 'No additional information'}
      </p>
      {link && (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#853a38', textDecoration: 'underline' }}
        >
          View Listing
        </a>
      )}

      {validImages.length > 0 ? (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '200px',
            marginTop: '15px',
          }}
        >
          {/* Main image */}
          <img
            src={validImages[currentImageIndex]}
            alt="Hostel Preview"
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
              borderRadius: '8px',
            }}
          />

          {/* Navigation buttons */}
          {validImages.length > 1 && (
            <>
              <button
                onClick={() => navigateImages('prev')}
                style={navButtonStyle('left')}
                aria-label="Previous"
              >
                ←
              </button>
              <button
                onClick={() => navigateImages('next')}
                style={navButtonStyle('right')}
                aria-label="Next"
              >
                →
              </button>
              {/* Image counter */}
              <div style={counterStyle}>
                {currentImageIndex + 1} / {validImages.length}
              </div>
            </>
          )}
        </div>
      ) : (
        <p style={{ marginTop: '10px', color: 'gray' }}>No images available</p>
      )}
    </div>
  );
}

/* Reusable styles for nav buttons & counter */
const navButtonStyle = (side) => ({
  position: 'absolute',
  top: '50%',
  [side]: '10px',
  transform: 'translateY(-50%)',
  background: 'rgba(0,0,0,0.5)',
  color: '#fff',
  border: 'none',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  cursor: 'pointer',
});

const counterStyle = {
  position: 'absolute',
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'rgba(0,0,0,0.6)',
  color: 'white',
  padding: '4px 8px',
  borderRadius: '12px',
  fontSize: '12px',
};

export default HostelPreview;
