import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// Auth
import { AuthProvider } from './context/AuthContext';

// Components
import LinkJourneyCode from './components/LinkJourneyCode';
import Header from './components/Header';
import PasswordReset from './components/PasswordReset';
import CodeLogin from './CodeLogin';
import JourneyPage from './components/JourneyPage';
import PaymentPage from './PaymentPage';
import PaymentCancelled from './PaymentCancelled';

// Admin login & route guard
import AdminLogin from './admin/AdminLogin';
import AdminRoute from './admin/AdminRoute';
import AdminLayout from './admin/AdminLayout';
import AdminJourneysPage from './admin/AdminJourneysPage';
import AdminHostelsPage from './admin/AdminHostelsPage';
import AdminUsersPage from './admin/AdminUsersPage';

// Other user-facing pages
import UserInfoForm from './UserInfoForm';
import GlobePage from './components/GlobePage';
import NotFoundPage from './404';
import UnifiedSignIn from './components/UnifiedSignIn';
import UserDashboard from './components/UserDashboard';
import StorePage from './components/StorePage';
import MemoriesPage from './components/MemoriesPage';
import DeleteAccount from './components/DeleteAccount';

import './App.css';

// Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function AppContent() {
  const location = useLocation();

  return (
    <div className="App">
      {/* Show the Header on all routes EXCEPT "/" */}
      {location.pathname !== '/' && <Header />}

      <div className="main-content">
        <Routes>
          {/* Root path: CodeLogin */}
          <Route path="/" element={<CodeLogin />} />

          {/* Link Journey Code */}
          <Route path="/link-code" element={<LinkJourneyCode />} />

          {/* Unified Sign In */}
          <Route path="/signin" element={<UnifiedSignIn />} />

          {/* Admin Login Page */}
          <Route path="/admin-login" element={<AdminLogin />} />

          {/* Param-based route for Journey */}
          <Route path="/journey/:journeyId" element={<JourneyPage />} />

          {/* Payment-related routes */}
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/payment-cancelled" element={<PaymentCancelled />} />

          {/* Protected admin routes: nest under /admin */}
          <Route
            path="/admin"
            element={
              <AdminRoute>
                <AdminLayout />
              </AdminRoute>
            }
          >
            {/* Index route -> /admin => /admin/journeys */}
            <Route index element={<AdminJourneysPage />} />
            <Route path="journeys" element={<AdminJourneysPage />} />
            <Route path="hostels" element={<AdminHostelsPage />} />
            <Route path="users" element={<AdminUsersPage />} />
          </Route>

          {/* User info form */}
          <Route path="/user-info" element={<UserInfoForm />} />

          {/* GlobePage */}
          <Route path="/globe" element={<GlobePage />} />

          {/* User Dashboard */}
          <Route path="/dashboard" element={<UserDashboard />} />

          {/* Password Reset */}
          <Route path="/password-reset" element={<PasswordReset />} />

          {/* Store Page */}
          <Route path="/store" element={<StorePage />} />

          {/* Memories/Achievements Page */}
          <Route path="/memories" element={<MemoriesPage />} />

          {/* Delete Account Page */}
          <Route path="/delete-account" element={<DeleteAccount />} />

          {/* Catch-all route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>

      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} BackPass. All rights reserved.</p>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </Elements>
  );
}

export default App;
