import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './Header.css';
import logo from '../images/logodownsized.png';

const Header = () => {
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [randomGreeting, setRandomGreeting] = useState('');
  const navigate = useNavigate();

  // 20 slang/cool greetings
  const greetings = [
    'Ciao',
    'Ayo',
    'Howdy',
    'Coucou',
    'Wagwan',
    'Bing Bong',
    "C'kemi",
    'Hola',
    'Salut',
    'Oi',
    'Hej',
    'Servus',
    'Hallo',
    "G'day",
    'Marhaba',
    'Ahlan',
    'Privet',
    'Salaam',
    'Aloha',
    'Konnichiwa',
  ];

  // Pick a random greeting once on mount
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * greetings.length);
    setRandomGreeting(greetings[randomIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen for auth changes and load user's info from Firestore
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        try {
          const emailLower = currentUser.email.toLowerCase();
          const userRef = doc(db, 'users', emailLower);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();
            setFirstName(userData.firstName || '');
          } else {
            setFirstName('');
          }
        } catch (err) {
          console.error('Error fetching user doc:', err);
          setFirstName('');
        }
      } else {
        setUser(null);
        setFirstName('');
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        navigate('/signin');
      })
      .catch((err) => {
        console.error('Logout error:', err);
      });
  };

  return (
    <header className="header">
      {/* Top row: greeting + name (center), sign in/out (right) */}
      <div className="header-top">
        <div className="top-center">
          {user && firstName && (
            <span
              className="username"
              onClick={() => navigate('/dashboard')}
            >
              {randomGreeting}, {firstName}
            </span>
          )}
        </div>

        <div className="top-right">
          {user ? (
            <button className="not-you-link" onClick={handleLogout}>
              Not you?
            </button>
          ) : (
            <Link to="/signin" className="signin-link">
              Sign In
            </Link>
          )}
        </div>
      </div>

      {/* Bottom row: logo (center) */}
      <div className="header-bottom">
        <Link to="/" className="logo-link">
          <img src={logo} alt="BackPass Logo" className="header-logo" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
