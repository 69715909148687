// src/components/LinkJourneyCode.js
import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, arrayUnion, getDocs, query, where, collection } from 'firebase/firestore';

/**
 * This component lets a signed-in user enter a journey code.
 * If valid, it updates the user's Firestore doc with the journey ID.
 */
function LinkJourneyCode() {
  const [code, setCode] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  // Keep track of the currently signed-in user
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return () => unsub();
  }, []);

  // Handle code submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('');
    setError('');

    if (!currentUser) {
      setError('You must be signed in to link a journey code.');
      return;
    }

    try {
      // 1) Find the journey doc that has this code
      const journeysRef = collection(db, 'journeys');
      const q = query(journeysRef, where('code', '==', code));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('Invalid journey code. Please try again.');
        return;
      }

      // Assuming the first doc is the correct journey
      const journeyDoc = querySnapshot.docs[0];
      const journeyId = journeyDoc.id;

      // 2) Update the user's doc to include this journeyId
      const userRef = doc(db, 'users', currentUser.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // If user doc doesn't exist for some reason, create it
        await setDoc(userRef, {
          journeys: [journeyId],
        });
      } else {
        // If user doc exists, push this journey ID into the array
        // (arrayUnion will only add if it doesn't already exist)
        await setDoc(
          userRef,
          { journeys: arrayUnion(journeyId) },
          { merge: true }
        );
      }

      setStatus(`Successfully linked journey: ${journeyId}`);
    } catch (err) {
      console.error('Error linking journey code:', err);
      setError(err.message);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>Link a Journey Code</h2>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {status && <p style={{ color: 'green' }}>{status}</p>}

      <form onSubmit={handleSubmit}>
        <label>Journey Code:</label>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter your code"
          required
        />
        <button type="submit">Link Journey</button>
      </form>
    </div>
  );
}

export default LinkJourneyCode;
