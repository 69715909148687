// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration using environment variables
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: "backpassbeta.firebasestorage.app",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Validate environment variables
const missingKeys = Object.entries(firebaseConfig)
  .filter(([key, value]) => !value)
  .map(([key]) => key);

if (missingKeys.length > 0) {
  console.error(`❌ Firebase is missing the following environment variables: ${missingKeys.join(', ')}`);
  throw new Error("Firebase configuration is incomplete. Check your .env file.");
}

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// If you want an explicit storage reference to "gs://backpassbeta.firebasestorage.app"
const storage = getStorage(app, "gs://backpassbeta.firebasestorage.app");

// Remove or comment out the signInAnonymously call:
// signInAnonymously(auth)
//   .then(() => {
//     console.log("✅ Signed in anonymously");
//   })
//   .catch((error) => {
//     console.error("❌ Anonymous sign-in failed:", error);
//   });

console.log("✅ Firebase initialized successfully!");
console.log(`🔹 Project ID: ${firebaseConfig.projectId}`);
console.log(`🔹 Storage Bucket: ${firebaseConfig.storageBucket}`);

export { auth, db, storage };
