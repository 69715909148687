// src/components/MemoriesPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function MemoriesPage() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Memories & Achievements</h2>
      <p>All your past journeys and achievements will appear here, insha’Allah.</p>
      <button
        onClick={handleBackClick}
        style={{
          marginTop: 20,
          padding: '10px 20px',
          backgroundColor: '#853a38',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
      >
        Back to Home
      </button>
    </div>
  );
}

export default MemoriesPage;
