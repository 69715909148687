import React, { useState } from 'react';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  async function handlePasswordReset(e) {
    e.preventDefault();
    setError('');
    setStatus('');
    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setStatus('Password reset email sent. Please check your inbox.');
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  }

  return (
    <div style={{ padding: 20 }}>
      <h2>Password Reset</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {status && <p style={{ color: 'green' }}>{status}</p>}
      <form onSubmit={handlePasswordReset}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          style={{ marginRight: '10px' }}
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Sending reset email...' : 'Send Password Reset Email'}
        </button>
      </form>
    </div>
  );
}

export default PasswordReset;
