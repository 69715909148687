// src/components/UserDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { sendEmailVerification } from 'firebase/auth';

function UserDashboard() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false); // track if we've checked auth

  const [journeyIds, setJourneyIds] = useState([]);
  const [journeyDocs, setJourneyDocs] = useState([]);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');

  // track verification status and loading state for sending verification
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [verificationSending, setVerificationSending] = useState(false);

  // ====================
  // Listen for auth changes, set user + verification data
  // ====================
  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthChecked(true); // We have now checked auth at least once

      if (user) {
        setEmailVerified(user.emailVerified);
        setPhoneNumber(user.phoneNumber || null);
      } else {
        setEmailVerified(false);
        setPhoneNumber(null);
      }
    });
    return () => unsub();
  }, []);

  // ====================
  // Once we know currentUser (and have checked auth), load their user doc
  // ====================
  useEffect(() => {
    if (!authChecked) return;

    if (!currentUser) {
      navigate('/signin');
      return;
    }

    // Because we store by email:
    const emailLower = currentUser.email.toLowerCase();
    const userRef = doc(db, 'users', emailLower);

    getDoc(userRef).then((snap) => {
      if (snap.exists()) {
        const data = snap.data();
        setJourneyIds(data.journeys || []);
      }
    });
  }, [authChecked, currentUser, navigate]);

  // ====================
  // Whenever journeyIds change, fetch the actual journey docs
  // ====================
  useEffect(() => {
    if (journeyIds.length === 0) {
      setJourneyDocs([]);
      return;
    }

    async function fetchJourneys() {
      try {
        const q = query(
          collection(db, 'journeys'),
          where('__name__', 'in', journeyIds)
        );
        const qsnap = await getDocs(q);
        const docsData = qsnap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setJourneyDocs(docsData);
      } catch (err) {
        console.error(err);
      }
    }

    fetchJourneys();
  }, [journeyIds]);

  // ====================
  // Send verification email
  // ====================
  async function handleSendVerificationEmail() {
    if (!currentUser) return;
    setVerificationSending(true);
    try {
      await sendEmailVerification(currentUser);
      alert('Verification email sent. Please check your inbox.');
    } catch (err) {
      console.error('Error sending verification email:', err);
      alert(`Failed to send verification email: ${err.message}`);
    }
    setVerificationSending(false);
  }

  // ====================
  // Link a New Journey Code
  // ====================
  async function handleLinkCode(e) {
    e.preventDefault();
    setError('');
    setStatus('');

    if (!currentUser) {
      setError('You must be logged in to link a journey code.');
      return;
    }

    try {
      const journeysRef = collection(db, 'journeys');
      const codeQuery = query(journeysRef, where('code', '==', code));
      const querySnapshot = await getDocs(codeQuery);

      if (querySnapshot.empty) {
        setError('Invalid journey code. Please try again.');
        return;
      }

      const journeyDoc = querySnapshot.docs[0];
      const journeyId = journeyDoc.id;

      const emailLower = currentUser.email.toLowerCase();
      const userRef = doc(db, 'users', emailLower);

      await updateDoc(userRef, {
        journeys: arrayUnion(journeyId),
      });

      setJourneyIds((prev) => [...prev, journeyId]);
      setStatus(`Successfully linked journey: ${journeyId}`);
      setCode('');
    } catch (err) {
      console.error('Error linking journey code:', err);
      setError(err.message);
    }
  }

  // ====================
  // Logout
  // ====================
  function handleLogout() {
    auth.signOut().then(() => {
      navigate('/signin');
    });
  }

  // If we haven't checked auth yet, show a small loading message
  if (!authChecked) {
    return (
      <div style={{ padding: 20, color: 'white', textAlign: 'center' }}>
        <h2>Loading...</h2>
      </div>
    );
  }

  return (
    <div
      style={{
        maxWidth: '800px',
        margin: '10px auto',
        backgroundColor: 'rgba(255, 255, 255, 0.0)',
        color: 'white',
        padding: '20px',
        borderRadius: '0px',
      }}
    >
      <h1
        style={{
          marginBottom: '15px',
          marginTop: '0px',
          textAlign: 'center',
          color: '#853a38',
          fontSize: '1.75rem',
        }}
      >
        User Dashboard
      </h1>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {status && <p style={{ color: 'lightgreen' }}>{status}</p>}

      {/* Manage My Info */}
      <div
        style={{
          marginBottom: '1em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>Personal Information</h3>
        <p>View or update your info, verify phone/email, and upload ID docs.</p>
        <button
          onClick={() => navigate('/user-info')}
          style={{
            padding: '8px 12px',
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Manage My Info
        </button>
      </div>

      {/* Verification Status */}
      <div
        style={{
          marginBottom: '1em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>Verification Status</h3>
        <p>Email: {emailVerified ? 'Verified' : 'Not Verified'}</p>
        <p>
          Phone:{' '}
          {phoneNumber ? `Verified (${phoneNumber})` : 'No phone number on file'}
        </p>
        {/* Send Verification Email button if email not verified */}
        {!emailVerified && (
          <div style={{ marginTop: '1em' }}>
            <button
              onClick={handleSendVerificationEmail}
              disabled={verificationSending}
              style={{
                padding: '8px 12px',
                backgroundColor: '#853a38',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: verificationSending ? 'not-allowed' : 'pointer',
              }}
            >
              {verificationSending
                ? 'Sending verification email...'
                : 'Send Verification Email'}
            </button>
          </div>
        )}
        {/* Add Phone Number button if no phone number on file */}
        {!phoneNumber && (
          <div style={{ marginTop: '1em' }}>
            <button
              onClick={() => navigate('/add-phone')}
              style={{
                padding: '8px 12px',
                backgroundColor: '#006400',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Add Phone Number
            </button>
          </div>
        )}
      </div>

      {/* Link Code Section */}
      <div
        style={{
          marginBottom: '1em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>Link a Journey Code</h3>
        <form onSubmit={handleLinkCode} style={{ display: 'flex', gap: '10px' }}>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter your journey code"
            required
            style={{ flex: 1, padding: '8px' }}
          />
          <button
            type="submit"
            style={{
              padding: '8px 12px',
              backgroundColor: '#853a38',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Link Code
          </button>
        </form>
      </div>

      {/* Journeys Section */}
      <div
        style={{
          marginBottom: '1em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>My Journeys</h3>
        {journeyDocs.length === 0 ? (
          <>
            <p>No journeys linked yet.</p>
            {/* If user has no journeys, show a "Buy a Pass" button */}
            <button
              onClick={() => navigate('/store')}
              style={{
                padding: '8px 12px',
                backgroundColor: '#006400',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Buy a Pass
            </button>
          </>
        ) : (
          <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
            {journeyDocs.map((journey) => (
              <li key={journey.id} style={{ marginBottom: '0.5em' }}>
                {/* Show journey.name instead of doc ID */}
                <strong>{journey.name || journey.id}</strong> – Code: {journey.code}{' '}
                <button
                  onClick={() => navigate(`/journey/${journey.id}`)}
                  style={{
                    marginLeft: '10px',
                    padding: '6px 12px',
                    backgroundColor: '#853a38',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  View Journey
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Memories / Achievements */}
      <div
        style={{
          marginBottom: '1em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>Memories & Achievements</h3>
        <p>Coming soon... or click below to see the new page!</p>
        <button
          onClick={() => navigate('/memories')}
          style={{
            padding: '8px 12px',
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Go to Memories
        </button>
      </div>

      {/* Store / Upgrades Stub */}
      <div
        style={{
          marginBottom: '1em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>Store / Upgrades</h3>
        <p>Coming soon... or click below to see the new page!</p>
        <button
          onClick={() => navigate('/store')}
          style={{
            padding: '8px 12px',
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Go to Store
        </button>
      </div>

      {/* Password Reset Link */}
      <div
        style={{
          marginBottom: '1em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>Password Reset</h3>
        <p>If you need to reset your password, click below.</p>
        <button
          onClick={() => navigate('/password-reset')}
          style={{
            padding: '8px 12px',
            backgroundColor: '#1e90ff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Reset Password
        </button>
      </div>

      {/* Logout */}
      <div style={{ marginTop: '1em' }}>
        <button
          onClick={handleLogout}
          style={{
            padding: '8px 12px',
            backgroundColor: '#800020',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Logout
        </button>
      </div>

      {/* Back to Home Button */}
      <div style={{ marginTop: '1em' }}>
        <button
          onClick={() => navigate('/')}
          style={{
            padding: '8px 12px',
            backgroundColor: '#444',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Back to Home
        </button>
      </div>

      {/* ========== New Section for Account Deletion ========== */}
      <div
        style={{
          marginTop: '2em',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '1em',
          borderRadius: '8px',
        }}
      >
        <h3>Account Deletion</h3>
        <p>oof ouch owie.</p>
        <button
          onClick={() => navigate('/delete-account')}
          style={{
            padding: '8px 12px',
            backgroundColor: '#a52a2a',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          (Don't) Push me.
        </button>
      </div>
    </div>
  );
}

export default UserDashboard;
