import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const Hostel = ({ hostelId }) => {
  const [hostelData, setHostelData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [validImages, setValidImages] = useState([]);

  // Carousel state management
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const fetchHostelData = async () => {
      try {
        const docRef = doc(db, 'hostels', hostelId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setHostelData(data);
          
          // Validate and filter images
          if (data.images && data.images.length > 0) {
            const imagePromises = data.images.map(imageUrl => 
              new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(imageUrl);
                img.onerror = () => resolve(null);
                img.src = imageUrl;
              })
            );

            const loadedImages = await Promise.all(imagePromises);
            setValidImages(loadedImages.filter(img => img !== null));
          }
        } else {
          setError('Hostel information could not be located.');
        }
      } catch (fetchError) {
        console.error('Error retrieving hostel data:', fetchError);
        setError('Unable to load hostel details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchHostelData();
  }, [hostelId]);

  // Image navigation method
  const navigateImages = (direction) => {
    const totalImages = validImages.length;
    setCurrentImageIndex((prev) => {
      if (direction === 'next') {
        return prev === totalImages - 1 ? 0 : prev + 1;
      }
      return prev === 0 ? totalImages - 1 : prev - 1;
    });
  };

  // Render loading state
  if (loading) {
    return (
      <div className="hostel-loading" style={{ textAlign: 'center', padding: '20px' }}>
        <p>Loading hostel information...</p>
      </div>
    );
  }

  // Render error state
  if (error || !hostelData) {
    return (
      <div className="hostel-error" style={{ textAlign: 'center', color: 'red', padding: '20px' }}>
        <p>{error || 'Hostel details are currently unavailable.'}</p>
      </div>
    );
  }

  return (
    <div className="hostel-container" style={{ maxWidth: '400px', margin: '0 auto', padding: '15px' }}>
      <h2>{hostelData.name}</h2>
      
      {/* Hostel Details */}
      <div className="hostel-details" style={{ marginBottom: '15px' }}>
        <p>
          <strong>Contact:</strong> {hostelData.contact || 'Not provided'}
        </p>
        <p>
          <strong>City:</strong> {hostelData.cityName || 'Not specified'}
        </p>
        <p>
          <strong>Notes:</strong> {hostelData.notes || 'No additional information'}
        </p>
        
        {hostelData.link && (
          <a
            href={hostelData.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ 
              display: 'inline-block', 
              marginTop: '10px', 
              color: '#853a38', 
              textDecoration: 'underline' 
            }}
          >
            View Listing
          </a>
        )}
      </div>

      {/* Image Display */}
      {validImages.length > 0 ? (
        <div
          className="hostel-carousel"
          style={{
            position: 'relative',
            width: '100%',
            maxWidth: '300px',
            height: '200px',
            margin: '0 auto',
          }}
        >
          {/* Main Image */}
          <img
            src={validImages[currentImageIndex]}
            alt="Hostel view"
            style={{
              width: '100%',
              height: '200px',
              objectFit: 'cover',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            }}
          />

          {/* Navigation Controls */}
          {validImages.length > 1 && (
            <>
              {/* Previous Image Button */}
              <button
                onClick={() => navigateImages('prev')}
                aria-label="Previous image"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '10px',
                  transform: 'translateY(-50%)',
                  background: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                ←
              </button>

              {/* Next Image Button */}
              <button
                onClick={() => navigateImages('next')}
                aria-label="Next image"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '10px',
                  transform: 'translateY(-50%)',
                  background: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                →
              </button>

              {/* Image Counter */}
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  background: 'rgba(0,0,0,0.6)',
                  color: 'white',
                  padding: '5px 10px',
                  borderRadius: '20px',
                  fontSize: '12px',
                }}
              >
                {currentImageIndex + 1} / {validImages.length}
              </div>
            </>
          )}
        </div>
      ) : (
        <p style={{ textAlign: 'center', color: 'gray' }}>No images available</p>
      )}
    </div>
  );
};

export default Hostel;