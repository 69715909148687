// src/CodeLogin.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore';
import { db, auth } from './firebase';
import './App.css';
import './CodeLogin.css';
import logo from './images/logodownsized2.png';

function CodeLogin({ onAccess = () => {} }) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState(''); // store user's firstName
  const navigate = useNavigate();

  const masterCode = process.env.REACT_APP_MASTER_CODE;

  // Listen for auth state, fetch firstName from Firestore if logged in
  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Since you're using email-based doc IDs:
          const emailLower = user.email.toLowerCase();
          const userRef = doc(db, 'users', emailLower);
          const snap = await getDoc(userRef);
          if (snap.exists()) {
            const data = snap.data();
            setFirstName(data.firstName || '');
          } else {
            setFirstName('');
          }
        } catch (err) {
          console.error('Error fetching user doc:', err);
          setFirstName('');
        }
      } else {
        setFirstName('');
      }
    });
    return () => unsub();
  }, []);

  // Check if user doc is "complete" in Firestore
  const isUserDocComplete = async (emailLower) => {
    try {
      const userRef = doc(db, 'users', emailLower);
      const snap = await getDoc(userRef);
      if (!snap.exists()) return false;

      const data = snap.data();
      // Adjust fields as needed for "complete" profile
      const requiredFields = ['firstName', 'email', 'passportId'];
      return requiredFields.every((f) => data[f] && data[f].trim() !== '');
    } catch (err) {
      console.error('Error checking user doc:', err);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // If user enters MASTER code, go to admin login
    if (code === masterCode) {
      navigate('/admin-login');
      return;
    }

    try {
      const journeysRef = collection(db, 'journeys');
      const q = query(journeysRef, where('code', '==', code));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('Invalid code');
        return;
      }

      const docSnap = querySnapshot.docs[0];
      const journeyData = docSnap.data();
      const journeyId = docSnap.id;

      // Payment check
      if (!journeyData.paymentStatus) {
        navigate('/payment', { state: { journeyId, journeyData } });
        return;
      }

      // Must be logged in to proceed
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert('Code is right, sign in tho.');
        navigate('/signin');
        return;
      }

      // Because you're using email-based docs:
      const emailLower = currentUser.email.toLowerCase();
      const complete = await isUserDocComplete(emailLower);
      if (!complete) {
        // Go to user info form
        navigate('/user-info', { state: { journeyData, journeyId } });
        return;
      }

      // If user doc is complete, proceed to the journey
      onAccess(journeyData, journeyId);
      // If your router expects /journey/:journeyId, do this:
      navigate(`/journey/${journeyId}`);
    } catch (err) {
      console.error('Error fetching journey data:', err);
      setError('Error fetching journey data');
    }
  };

  const currentUser = auth.currentUser;

  return (
    <div className="outer-container">
      <img src={logo} alt="BackPass Logo" className="logo" />

      <div className="code-container">
        <h2>Early Access</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter Your Code Here"
            required
          />
          <button type="submit">Push Me</button>
        </form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>

      {/* Extra Button - placed outside the code-container */}
      <div
        style={{
          marginTop: '60px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {currentUser ? (
          // If logged in, show "Hey (firstName)"
          <button
            onClick={() => navigate('/dashboard')}
            style={{
              backgroundColor: '#FFD700',
              color: '#800020',
              border: 'none',
              padding: '1rem 2rem',
              cursor: 'pointer',
              fontSize: '1rem',
              borderRadius: '5px',
              boxShadow: '0 0 5px rgba(255, 215, 0, 0.7)',
              transition: 'box-shadow 0.3s ease',
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.boxShadow = '0 0 8px rgba(255, 215, 0, 1)')
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.boxShadow = '0 0 5px rgba(255, 215, 0, 0.7)')
            }
          >
            {`Hey ${firstName || 'Friend'}`}
          </button>
        ) : (
          // If not logged in, show "Come In"
          <button
            onClick={() => navigate('/signin')}
            style={{
              backgroundColor: 'rgba(0, 68, 0, 0.8)',
              color: 'white',
              border: 'none',
              padding: '1rem 2rem',
              cursor: 'pointer',
              fontSize: '1rem',
              borderRadius: '5px',
              boxShadow: '0 0 4px rgba(0, 68, 0, 0.4)',
              transition: 'box-shadow 0.3s ease',
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.boxShadow = '0 0 6px rgba(0, 68, 0, 0.7)')
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.boxShadow = '0 0 4px rgba(0, 68, 0, 0.4)')
            }
          >
            Come In
          </button>
        )}
      </div>
    </div>
  );
}

export default CodeLogin;
