import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';             // <-- Now importing db
import { doc, deleteDoc } from 'firebase/firestore'; // <-- doc & deleteDoc for Firestore
import { signInWithEmailAndPassword } from 'firebase/auth';

function DeleteAccount() {
  const [currentUser, setCurrentUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // For re-auth flow
  const [reauthNeeded, setReauthNeeded] = useState(false);
  const [reauthEmail, setReauthEmail] = useState('');
  const [reauthPassword, setReauthPassword] = useState('');

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setAuthChecked(true);
    });
    return () => unsub();
  }, []);

  // 1) Attempt to delete
  async function handleDelete() {
    setError('');

    if (!currentUser) {
      setError('You are not signed in. There is no account to delete.');
      return;
    }

    try {
      // Delete Firestore user doc
      const emailLower = currentUser.email.toLowerCase();
      const userRef = doc(db, 'users', emailLower);
      await deleteDoc(userRef);

      // Attempt to delete the Firebase Auth user
      await currentUser.delete();

      alert('Your account is now deleted (including Firestore doc).');
      navigate('/');
    } catch (err) {
      console.error('Error deleting account:', err);

      // If Firebase says "requires recent login", show the re-auth form
      if (err.code === 'auth/requires-recent-login') {
        setReauthNeeded(true);
      } else {
        setError(`Failed to delete account: ${err.message}`);
      }
    }
  }

  // 2) If re-auth is needed, let them sign in again
  async function handleReauth(e) {
    e.preventDefault();
    setError('');

    try {
      // Re-authenticate
      await signInWithEmailAndPassword(auth, reauthEmail, reauthPassword);

      // Now user is re-authed, try deleting again
      const freshUser = auth.currentUser;
      if (!freshUser) {
        throw new Error('User is no longer signed in after reauth. Please sign in again.');
      }

      // Delete Firestore user doc
      const emailLower = freshUser.email.toLowerCase();
      const userRef = doc(db, 'users', emailLower);
      await deleteDoc(userRef);

      // Delete the Firebase Auth user
      await freshUser.delete();

      alert('Your account has been deleted (including Firestore doc).');
      navigate('/');
    } catch (error) {
      console.error('Error reauthing or deleting after reauth:', error);
      setError(error.message);
    }
  }

  // If we haven't checked auth yet, show a small loading message
  if (!authChecked) {
    return (
      <div style={{ padding: 20, color: 'white', textAlign: 'center' }}>
        <h2>Loading...</h2>
      </div>
    );
  }

  // If user is not logged in, show error / redirect
  if (!currentUser) {
    return (
      <div style={{ padding: 20, color: 'white', textAlign: 'center' }}>
        <h2>You are not logged in.</h2>
        <button
          onClick={() => navigate('/signin')}
          style={{
            marginTop: '1em',
            padding: '10px 15px',
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Sign In
        </button>
      </div>
    );
  }

  // If reauth is needed, show re-auth form
  if (reauthNeeded) {
    return (
      <div
        style={{
          maxWidth: '400px',
          margin: '20px auto',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          color: '#333',
          padding: '20px',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <h1>Re-Authenticate</h1>
        <p>
          Depending on our security's opinion of you, you may need to re-enter
          your email and password to proceed (Last Time we promise).
        </p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <form onSubmit={handleReauth} style={{ marginTop: '1em' }}>
          <div style={{ marginBottom: '1em' }}>
            <input
              type="email"
              placeholder="Email"
              value={reauthEmail}
              onChange={(e) => setReauthEmail(e.target.value)}
              required
              style={{ padding: '8px', width: '100%' }}
            />
          </div>
          <div style={{ marginBottom: '1em' }}>
            <input
              type="password"
              placeholder="Password"
              value={reauthPassword}
              onChange={(e) => setReauthPassword(e.target.value)}
              required
              style={{ padding: '8px', width: '100%' }}
            />
          </div>
          <button
            type="submit"
            style={{
              padding: '10px 15px',
              backgroundColor: '#853a38',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Re-Authenticate &amp; Delete
          </button>
        </form>
        <button
          onClick={() => navigate('/dashboard')}
          style={{
            marginTop: '1em',
            padding: '10px 15px',
            backgroundColor: '#444',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Cancel
        </button>
      </div>
    );
  }

  // Otherwise, show normal delete page
  return (
    <div
      style={{
        maxWidth: '800px',
        margin: '20px auto',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        color: '#333',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
      }}
    >
      <h1>pls no</h1>
      <p>Our database might make you re-sign in.</p>
      <p>(We hope you don't) push the button.</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <button
        onClick={handleDelete}
        style={{
          marginTop: '1em',
          padding: '10px 15px',
          backgroundColor: '#a52a2a',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Delete My Account
      </button>

      <div style={{ marginTop: '2em' }}>
        <button
          onClick={() => navigate('/dashboard')}
          style={{
            padding: '10px 15px',
            backgroundColor: '#444',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Cancel / Go Back
        </button>
      </div>
    </div>
  );
}

export default DeleteAccount;
