import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import AdminJourneyUsers from './AdminJourneyUsers';

function AdminUsersPage() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    nationality: '',
    address: '',
    passportId: '',
    passportURL: '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setLoading(true);
      try {
        const snap = await getDocs(collection(db, 'users'));
        if (!isMounted) return;

        const usersData = snap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setUsers(usersData);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to load users');
      } finally {
        if (isMounted) setLoading(false);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // placeholders for add/edit/delete user
  const handleUserInputChange = (e) => {
    // ...
  };
  const handleSaveUser = async () => {
    // ...
  };
  const handleEditUser = (user) => {
    setSelectedUser(user);
  };
  const handleDeleteUser = async (id) => {
    // ...
  };

  if (loading) {
    return <div>Loading Users...</div>;
  }

  return (
    <div>
      <h2>Manage Users</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <AdminJourneyUsers
        users={users}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        handleUserInputChange={handleUserInputChange}
        handleSaveUser={handleSaveUser}
        handleEditUser={handleEditUser}
        handleDeleteUser={handleDeleteUser}
      />
    </div>
  );
}

export default AdminUsersPage;
