// src/components/UnifiedSignIn.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import {
  loginWithEmail,
  registerWithEmail,
  logout,
} from '../AuthService';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendEmailVerification,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';

function UnifiedSignIn() {
  // Tab: 'email' or 'phone'
  const [activeTab, setActiveTab] = useState('email');

  // Auth mode for email: 'login' or 'register'
  const [authMode, setAuthMode] = useState('login');

  // Email form fields
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneForEmail, setPhoneForEmail] = useState(''); // optional phone for email registration
  const [password, setPassword] = useState('');

  // Phone auth fields
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);

  // UI state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return () => unsub();
  }, []);

  // ====================
  //  reCAPTCHA Setup
  // ====================
  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
          callback: () => {
            console.log('✅ reCAPTCHA verified');
          },
          'expired-callback': () => {
            console.warn('⚠️ reCAPTCHA expired, resetting...');
            window.recaptchaVerifier.clear();
            setupRecaptcha();
          },
        },
        auth
      );
      window.recaptchaVerifier.render();
    }
  };

  // ====================
  //   Phone Formatting
  // ====================
  const formatPhoneNumber = (input) => {
    if (!input) return '';
    let sanitized = input.replace(/[^\d+]/g, '');
    if (!sanitized.startsWith('+')) {
      sanitized = '+1' + sanitized;
    }
    return sanitized;
  };

  // ====================
  //  Email Auth Submit
  // ====================
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (!currentUser) {
        if (authMode === 'register') {
          // 1) Create new user via registerWithEmail
          const userCredential = await registerWithEmail(email, password, {
            firstName,
            phoneNumber: phoneForEmail,
          });

          // 2) Send email verification
          await sendEmailVerification(userCredential.user);
          alert('We sent a verification link to your email. Please verify before logging in.');

          // 3) Create a user doc in Firestore if it doesn’t exist
          const emailLower = userCredential.user.email.toLowerCase();
          const docRef = doc(db, 'users', emailLower);
          const docSnap = await getDoc(docRef);

          if (!docSnap.exists()) {
            await setDoc(docRef, {
              firstName,
              email: emailLower,
              phoneNumber: phoneForEmail,
              createdAt: serverTimestamp(),
            });
          }

          // 4) Immediately log them out, so they can't proceed until verified
          await logout();

          // 5) Navigate them to /userinfo with some state
          navigate('/userinfo', {
            state: {
              firstName,
              email: emailLower,
              phone: phoneForEmail,
            },
          });
        } else {
          // ========== LOGIN ==========
          const userCredential = await loginWithEmail(email, password);

          // If the user hasn’t verified their email, block them
          if (!userCredential.user.emailVerified) {
            await logout();
            throw new Error(
              'Your email is not verified. Please check your inbox for the verification link.'
            );
          }

          // If verified, proceed
          const emailLower = userCredential.user.email.toLowerCase();
          const docSnap = await getDoc(doc(db, 'users', emailLower));
          if (docSnap.exists()) {
            const userData = docSnap.data();
            alert(`Logged in as: ${userData.firstName} at ${userData.email}`);
          } else {
            alert(`Logged in as: ??? at ${userCredential.user.email}`);
          }

          // After login → /dashboard
          navigate('/dashboard');
        }
      } else {
        setError('Already signed in. Log out first if you want to sign in differently.');
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // ====================
  //  Phone Auth
  // ====================
  const handleSendCode = async () => {
    setError('');
    setLoading(true);
    try {
      setupRecaptcha();

      const formatted = formatPhoneNumber(phoneNumber);
      if (formatted.length < 10) {
        setError('Please enter a valid phone number, e.g. +1 555 555 1234.');
        setLoading(false);
        return;
      }

      const appVerifier = window.recaptchaVerifier;
      const result = await signInWithPhoneNumber(auth, formatted, appVerifier);
      setConfirmationResult(result);
      alert('📲 SMS code sent!');
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    setError('');
    setLoading(true);
    try {
      if (!confirmationResult) {
        setError('No confirmation result. Please request a code first.');
        setLoading(false);
        return;
      }
      const cred = await confirmationResult.confirm(verificationCode);

      alert(`Signed in with phone: ${cred.user.phoneNumber}`);
      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // ====================
  //  Logout Handler
  // ====================
  const handleLogout = async () => {
    setError('');
    setLoading(true);
    try {
      await logout();
      alert('Logged out successfully!');
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // ====================
  //   Render UI
  // ====================
  return (
    <div style={{ padding: '20px' }}>
      <h2>Sign In (Email or Phone)</h2>

      {currentUser && (
        <div style={{ marginBottom: '1em' }}>
          <button onClick={handleLogout} disabled={loading}>
            Logout
          </button>
        </div>
      )}

      <div style={{ marginBottom: '1em' }}>
        <button
          onClick={() => setActiveTab('email')}
          disabled={activeTab === 'email'}
        >
          Email
        </button>
        <button
          onClick={() => setActiveTab('phone')}
          disabled={activeTab === 'phone'}
        >
          Phone
        </button>
      </div>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading && <p>Loading...</p>}

      {/* ========== EMAIL SECTION ========== */}
      {activeTab === 'email' && (
        <div>
          <div style={{ marginBottom: '1em' }}>
            <button
              onClick={() => setAuthMode('login')}
              disabled={authMode === 'login'}
            >
              Login
            </button>
            <button
              onClick={() => setAuthMode('register')}
              disabled={authMode === 'register'}
            >
              Register
            </button>
          </div>

          <form onSubmit={handleEmailSubmit}>
            {/* If registering, ask for firstName & optional phone */}
            {authMode === 'register' && (
              <>
                <div>
                  <label>First Name: </label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label>Phone (optional): </label>
                  <input
                    type="tel"
                    value={phoneForEmail}
                    onChange={(e) => setPhoneForEmail(e.target.value)}
                    placeholder="e.g. 555-555-1234"
                  />
                </div>
              </>
            )}

            <div>
              <label>Email: </label>
              <input
                type="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div>
              <label>Password: </label>
              <input
                type="password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button type="submit" disabled={loading}>
              {authMode === 'register' ? 'Register' : 'Login'}
            </button>
          </form>
        </div>
      )}

      {/* ========== PHONE SECTION ========== */}
      {activeTab === 'phone' && (
        <div>
          {/* reCAPTCHA container */}
          <div id="recaptcha-container" />

          <div style={{ marginTop: '1rem' }}>
            <label>Phone Number: </label>
            <input
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="+1 555 555 1234"
              required
            />
            <button onClick={handleSendCode} disabled={loading}>
              Send Code
            </button>
          </div>

          {confirmationResult && (
            <div style={{ marginTop: '1rem' }}>
              <label>Verification Code: </label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="123456"
                required
              />
              <button onClick={handleVerifyCode} disabled={loading}>
                Verify Code
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default UnifiedSignIn;
