// src/admin/AdminJourneyJourneys.js
import React from 'react';

function AdminJourneyJourneys({
  journeys,
  selectedJourney,
  handleJourneyInputChange,
  handleSaveJourneyChanges,
  handleEditJourney,
  handleDeleteJourney,
  routes,
  getAssociatedRoutes,
  users,
}) {
  return (
    <div style={{ marginBottom: '30px' }}>
      <h3>Manage Journeys</h3>
      <div>
        <label>
          Journey Code:
          <input
            type="text"
            name="code"
            value={selectedJourney.code}
            onChange={handleJourneyInputChange}
          />
        </label>
        {/* We remove the "Name" field entirely */}
        
        <label>
          Days:
          <input
            type="number"
            name="days"
            value={selectedJourney.days}
            onChange={handleJourneyInputChange}
          />
        </label>
        <label>
          Cost:
          <input
            type="number"
            name="cost"
            value={selectedJourney.cost}
            onChange={handleJourneyInputChange}
          />
        </label>
        <label>
          Payment Status:
          <input
            type="checkbox"
            name="paymentStatus"
            checked={selectedJourney.paymentStatus}
            onChange={handleJourneyInputChange}
          />
        </label>
        <label>
          Routes:
          <select
            name="routeIds"
            value={selectedJourney.routeIds}
            onChange={(e) => {
              const opts = e.target.options;
              const selectedIds = [];
              for (let i = 0; i < opts.length; i++) {
                if (opts[i].selected) {
                  selectedIds.push(opts[i].value);
                }
              }
              handleJourneyInputChange({
                target: { name: 'routeIds', value: selectedIds },
              });
            }}
            multiple
          >
            {routes.map((r) => (
              <option key={r.id} value={r.id}>
                {r.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start Date:
          <input
            type="date"
            name="startDate"
            value={selectedJourney.startDate}
            onChange={handleJourneyInputChange}
          />
        </label>

        {/* Link to user doc */}
        <label>
          User:
          <select
            name="userId"
            value={selectedJourney.userId || ''}
            onChange={handleJourneyInputChange}
          >
            <option value="">(None)</option>
            {users.map((u) => (
              <option key={u.id} value={u.id}>
                {u.firstName} {u.lastName} ({u.email})
              </option>
            ))}
          </select>
        </label>

        <button onClick={handleSaveJourneyChanges}>
          {selectedJourney.id ? 'Update Journey' : 'Add Journey'}
        </button>
      </div>

      <h4>Current Journeys</h4>
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Days</th>
            <th>Cost</th>
            <th>Payment</th>
            <th>Routes</th>
            <th>Start Date</th>
            <th>User</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {journeys.map((j) => {
            const routeNames = getAssociatedRoutes(j);
            return (
              <tr key={j.id}>
                <td>{j.code}</td>
                <td>{j.days}</td>
                <td>{j.cost}</td>
                <td>{j.paymentStatus ? 'Paid' : 'Unpaid'}</td>
                <td>{routeNames}</td>
                <td>{j.startDate}</td>
                <td>{j.userId || ''}</td>
                <td>
                  <button onClick={() => handleEditJourney(j)}>Edit</button>
                  <button onClick={() => handleDeleteJourney(j.id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminJourneyJourneys;
