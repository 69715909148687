import React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function AdminLayout() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    if (!currentUser) return;
    await currentUser?.auth?.signOut();
    navigate('/admin-login');
  };

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      {/* Sidebar */}
      <div
        style={{
          width: '200px',
          backgroundColor: '#2B2B2B',
          padding: '20px',
          color: 'white',
        }}
      >
        <h3>Admin Menu</h3>
        <nav style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <NavLink
            to="journeys"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            Manage Journeys
          </NavLink>
          <NavLink
            to="hostels"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            Manage Hostels
          </NavLink>
          <NavLink
            to="users"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            Manage Users
          </NavLink>

          <button
            onClick={handleLogout}
            style={{
              marginTop: '20px',
              padding: '10px',
              backgroundColor: '#800020',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Logout
          </button>
        </nav>
      </div>

      {/* Main content area */}
      <div style={{ flex: 1, backgroundColor: 'white', padding: '20px' }}>
        <Outlet />
      </div>
    </div>
  );
}

export default AdminLayout;
