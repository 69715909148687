// src/UserInfoForm.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  deleteField,
  serverTimestamp,
} from 'firebase/firestore';
import { auth, db, storage } from './firebase';
import { sendEmailVerification } from 'firebase/auth';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';

import './UserInfoForm.css';

// Just simple arrays for dropdowns (no flags)
const countryList = [
  'Argentina',
  'Australia',
  'Brazil',
  'Canada',
  'China',
  'Egypt',
  'France',
  'Germany',
  'India',
  'Indonesia',
  'Italy',
  'Japan',
  'Mexico',
  'Nigeria',
  'Philippines',
  'South Africa',
  'South Korea',
  'Spain',
  'United Kingdom',
  'United States',
];
const languageList = [
  'English (American)',
  'English (British)',
];

function UserInfoForm() {
  const navigate = useNavigate();
  const location = useLocation(); // to read route state from registration

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    nationality: '',
    language: '',
    gender: '',
    phone: '',
    email: '',
    passportId: '',
    passportURL: '',
  });

  const [isFirstNameLocked, setIsFirstNameLocked] = useState(false);
  const [error, setError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  // For verification status
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    const loadUserDoc = async () => {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        navigate('/signin');
        return;
      }
      setEmailVerified(currentUser.emailVerified);

      const emailLower = currentUser.email.toLowerCase();
      const userRef = doc(db, 'users', emailLower);
      const snap = await getDoc(userRef);

      // Firestore doc data
      let docData = {};
      if (snap.exists()) {
        docData = snap.data();
      }

      // Route state from registration
      let routeState = location.state || {};

      // Merge doc data & route state
      const mergedFirstName = routeState.firstName || docData.firstName || '';
      const mergedLastName = routeState.lastName || docData.lastName || '';
      const mergedPhone = routeState.phone || docData.phoneNumber || '';

      setFormData((prev) => ({
        ...prev,
        firstName: mergedFirstName,
        lastName: mergedLastName,
        phone: mergedPhone,
        email: routeState.email || docData.email || currentUser.email || '',
        nationality: docData.nationality || '',
        language: docData.language || '',
        gender: docData.gender || '',
        passportId: docData.passportId || '',
        passportURL: docData.passportURL || '',
      }));

      // If doc had a firstName, lock it
      if (docData.firstName) {
        setIsFirstNameLocked(true);
      }
    };

    loadUserDoc();
  }, [navigate, location]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Limit phone number to + and 15 digits (max 16 chars total)
  const handlePhoneChange = (e) => {
    let raw = e.target.value;
    // Strip out non-digits except leading '+'
    raw = raw.replace(/[^\d+]/g, '');
    // If it doesn't start with '+', prepend it
    if (raw && !raw.startsWith('+')) {
      raw = '+' + raw;
    }
    // Enforce a max length of 16
    if (raw.length > 16) {
      raw = raw.slice(0, 16);
    }
    setFormData((prev) => ({ ...prev, phone: raw }));
  };

  // ============ UPLOAD PASSPORT IMAGE ============
  const handlePassportUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(0);

    const currentUser = auth.currentUser;
    if (!currentUser) {
      setError('No user is logged in. Please sign in first.');
      navigate('/signin');
      return;
    }

    const emailLower = currentUser.email.toLowerCase();
    const fileRef = ref(storage, `passportImages/${emailLower}/${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(prog);
      },
      (err) => {
        console.error('Upload error:', err);
        setError('Failed to upload passport image.');
        setIsUploading(false);
      },
      async () => {
        // Upload complete
        const downloadURL = await getDownloadURL(fileRef);
        setIsUploading(false);

        // Save the URL to the user doc
        const userRef = doc(db, 'users', emailLower);
        await setDoc(userRef, { passportURL: downloadURL }, { merge: true });
        setFormData((prev) => ({ ...prev, passportURL: downloadURL }));
      }
    );
  };

  // ============ DELETE PASSPORT IMAGE ============
  const handlePassportDelete = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      setError('No user is logged in. Please sign in first.');
      navigate('/signin');
      return;
    }

    if (!formData.passportURL) {
      return; // Nothing to delete
    }

    const decodedUrl = decodeURIComponent(formData.passportURL);
    const base = 'https://firebasestorage.googleapis.com/v0/b/';
    let filePath = decodedUrl.split('?')[0].replace(base, '');
    filePath = filePath.replace('backpassbeta.appspot.com/o/', '');
    filePath = filePath.replace(/%2F/g, '/');

    const fileRef = ref(storage, filePath);
    try {
      await deleteObject(fileRef);

      const emailLower = currentUser.email.toLowerCase();
      const userRef = doc(db, 'users', emailLower);
      await updateDoc(userRef, {
        passportURL: deleteField(),
      });
      setFormData((prev) => ({ ...prev, passportURL: '' }));
    } catch (err) {
      console.error('Error deleting passport image:', err);
      setError('Failed to delete passport image. Try again later.');
    }
  };

  // ============ SEND EMAIL VERIFICATION ============
  const handleSendVerificationEmail = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    try {
      await sendEmailVerification(currentUser);
      alert('Verification email sent. Please check your inbox.');
    } catch (err) {
      console.error('Error sending verification email:', err);
      alert(`Failed to send verification email: ${err.message}`);
    }
  };

  // ============ SUBMIT USER INFO FORM ============
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Required fields except for firstName if locked
    const requiredFields = ['lastName', 'email'];

    // Check firstName only if not locked
    if (!isFirstNameLocked && (!formData.firstName || !formData.firstName.trim())) {
      setError('Please provide your First Name.');
      return;
    }

    // Check other required fields
    for (const field of requiredFields) {
      if (!formData[field] || formData[field].trim() === '') {
        setError(`Please fill in the required field: ${field}`);
        return;
      }
    }

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert('No user is logged in. Please sign in first.');
        navigate('/signin');
        return;
      }

      const emailLower = currentUser.email.toLowerCase();
      const userRef = doc(db, 'users', emailLower);

      await setDoc(
        userRef,
        {
          // Only save firstName if it's not locked
          firstName: isFirstNameLocked ? formData.firstName : formData.firstName.trim(),
          lastName: formData.lastName.trim(),
          nationality: formData.nationality,
          language: formData.language,
          gender: formData.gender,
          phoneNumber: formData.phone,
          email: formData.email,
          passportId: formData.passportId, // optional
          updatedAt: serverTimestamp(),
        },
        { merge: true }
      );
      console.log('✅ Updated/created user doc with userInfo fields.');

      navigate('/dashboard');
    } catch (err) {
      console.error('Error updating user info:', err);
      setError('Failed to save your information. Please try again.');
    }
  };

  return (
    <div className="user-info-container">
      <button className="back-button" onClick={() => navigate(-1)}>
        Back
      </button>

      <h2 className="user-info-title">Who Dis?</h2>

      <form className="user-info-form" onSubmit={handleSubmit}>
        {error && <p className="error-text">{error}</p>}

        {/* First Name (locked if isFirstNameLocked) */}
        <div className="form-row">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
            disabled={isFirstNameLocked}
          />
        </div>

        {/* Last Name (required) */}
        <div className="form-row">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            type="text"
            name="lastName"
            placeholder="Last Name *"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        {/* Nationality (no flags, just a select) */}
        <div className="form-row">
          <label htmlFor="nationality">Nationality</label>
          <select
            id="nationality"
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
          >
            <option value="">Select your country</option>
            {countryList.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>

        {/* Language (no flags, just a select) */}
        <div className="form-row">
          <label htmlFor="language">Language</label>
          <select
            id="language"
            name="language"
            value={formData.language}
            onChange={handleChange}
          >
            <option value="">Select your language</option>
            {languageList.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </div>

        {/* Gender */}
        <div className="form-row">
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="">Select your gender</option>
            <option value="man">Man</option>
            <option value="woman">Woman</option>
            <option value="other">Other</option>
          </select>
        </div>

        {/* Phone Number (with area code) */}
        <div className="form-row">
          <label htmlFor="phone">Phone Number (with area code)</label>
          <input
            id="phone"
            type="tel"
            name="phone"
            placeholder="+1234567890"
            value={formData.phone}
            onChange={handlePhoneChange}
          />
        </div>

        {/* Email (required, locked) */}
        <div className="form-row">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Email Address *"
            value={formData.email}
            disabled
            required
          />
          {!emailVerified && (
            <button
              type="button"
              onClick={handleSendVerificationEmail}
              className="verify-button"
            >
              Verify Email
            </button>
          )}
        </div>

        {/* Passport Number (optional) */}
        <div className="form-row">
          <label htmlFor="passportId">Passport Number</label>
          <input
            id="passportId"
            type="text"
            name="passportId"
            placeholder="Passport Number"
            value={formData.passportId}
            onChange={handleChange}
          />
        </div>

        {/* Passport Picture */}
        <div className="form-row">
          <label>Passport Picture</label>
          {formData.passportURL ? (
            <img
              src={formData.passportURL}
              alt="Passport"
              style={{ width: '150px', border: '2px solid #555' }}
            />
          ) : (
            <p style={{ fontStyle: 'italic' }}>No passport image uploaded.</p>
          )}

          {isUploading && (
            <p style={{ color: '#FFD700' }}>Uploading: {uploadProgress}%</p>
          )}

          <div className="file-input-row">
            <label className="browse-button">
              Browse
              <input
                type="file"
                accept="image/*"
                onChange={handlePassportUpload}
                style={{ display: 'none' }}
              />
            </label>

            {formData.passportURL && (
              <button
                type="button"
                onClick={handlePassportDelete}
                className="delete-button"
              >
                Delete Passport Image
              </button>
            )}
          </div>
          <p className="passport-hint">
            (Optional, but many hostels require it. Show name, picture, and
            passport number. We don't check it; it's for them.)
          </p>
        </div>

        {/* Save & Continue Button (Green) */}
        <div className="button-row">
          <button type="submit" className="save-button">
            Save & Continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserInfoForm;
