// src/admin/AdminJourneysPage.js
import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../firebase';

// Subcomponents
import AdminJourneyJourneys from './AdminJourneyJourneys';
import AdminJourneyRoutes from './AdminJourneyRoutes';

function AdminJourneysPage() {
  const [journeys, setJourneys] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState({
    id: '',
    code: '',
    // name: '', <-- We won't use name from the form
    days: 0,
    cost: 0,
    paymentStatus: false,
    routeIds: [],
    startDate: '',
    userId: '',
  });

  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState({
    id: '',
    name: '',
    cityIds: [],
    notes: '',
  });

  // We'll also fetch users so we can populate the user dropdown
  const [users, setUsers] = useState([]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // ======================
  // Fetch Data on Mount
  // ======================
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        // fetch journeys + routes + users in parallel
        const [journeysSnap, routesSnap, usersSnap] = await Promise.all([
          getDocs(collection(db, 'journeys')),
          getDocs(collection(db, 'routes')),
          getDocs(collection(db, 'users')),
        ]);

        if (!isMounted) return;

        // Journeys
        const journeysData = journeysSnap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setJourneys(journeysData);

        // Routes
        const routesData = routesSnap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setRoutes(routesData);

        // Users
        const usersData = usersSnap.docs.map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }));
        setUsers(usersData);

      } catch (err) {
        console.error('Error fetching journeys/routes/users:', err);
        setError('Failed to load journeys/routes/users');
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // ======================
  // Journey Handlers
  // ======================

  // Called on form input changes (Journey Code, Days, etc.)
  const handleJourneyInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedJourney((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Called on "Add Journey" or "Update Journey"
  const handleSaveJourneyChanges = async () => {
    if (!selectedJourney.code) {
      setError('Journey code is required');
      return;
    }
    try {
      // We'll ignore selectedJourney.name and build it dynamically
      let finalName = 'Unnamed Journey';

      // 1) fetch user doc if userId is set
      let userFirstName = '';
      if (selectedJourney.userId) {
        const userRef = doc(db, 'users', selectedJourney.userId);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          userFirstName = userData.firstName || 'User';
        }
      }

      // 2) fetch the first route name if routeIds are set
      let firstRouteName = '';
      if (selectedJourney.routeIds.length > 0) {
        const firstRouteId = selectedJourney.routeIds[0];
        const routeRef = doc(db, 'routes', firstRouteId);
        const routeSnap = await getDoc(routeRef);
        if (routeSnap.exists()) {
          const routeData = routeSnap.data();
          firstRouteName = routeData.name || 'Unknown Route';
        }
      }

      // If we got both userFirstName and routeName, build a nice name
      if (userFirstName && firstRouteName) {
        finalName = `${userFirstName}'s ${firstRouteName}`;
      }

      // Build final journey object
      const journeyData = {
        code: selectedJourney.code,
        name: finalName, // dynamic name
        days: selectedJourney.days,
        cost: selectedJourney.cost,
        paymentStatus: selectedJourney.paymentStatus,
        routeIds: selectedJourney.routeIds,
        startDate: selectedJourney.startDate,
        userId: selectedJourney.userId || '',
      };

      if (selectedJourney.id) {
        // Editing existing journey
        await updateDoc(doc(db, 'journeys', selectedJourney.id), journeyData);
        alert('Journey updated successfully');
      } else {
        // Adding new
        await addDoc(collection(db, 'journeys'), journeyData);
        alert('Journey added successfully');
      }

      // Reset the form
      setSelectedJourney({
        id: '',
        code: '',
        days: 0,
        cost: 0,
        paymentStatus: false,
        routeIds: [],
        startDate: '',
        userId: '',
      });

      // Re-fetch journeys
      const journeysSnap = await getDocs(collection(db, 'journeys'));
      const freshJourneys = journeysSnap.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setJourneys(freshJourneys);

    } catch (err) {
      console.error('Error saving journey:', err);
      setError('Failed to save journey');
    }
  };

  // When user clicks "Edit"
  // We add fallback to avoid uncontrolled->controlled warnings
  const handleEditJourney = (journey) => {
    setSelectedJourney({
      id: journey.id || '',
      code: journey.code ?? '',
      days: journey.days ?? 0,
      cost: journey.cost ?? 0,
      paymentStatus: journey.paymentStatus ?? false,
      routeIds: journey.routeIds ?? [],
      startDate: journey.startDate ?? '',
      userId: journey.userId ?? '',
    });
  };

  // When user clicks "Delete"
  const handleDeleteJourney = async (id) => {
    try {
      await deleteDoc(doc(db, 'journeys', id));
      alert('Journey deleted successfully');

      // Refresh journeys
      const journeysSnap = await getDocs(collection(db, 'journeys'));
      const freshJourneys = journeysSnap.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      setJourneys(freshJourneys);

    } catch (err) {
      console.error('Error deleting journey:', err);
      setError('Failed to delete journey');
    }
  };

  // ======================
  // Route Handlers (Placeholder)
  // ======================
  const handleRouteInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedRoute((prev) => ({ ...prev, [name]: value }));
  };

  const handleRouteCitySelection = (e) => {
    const opts = e.target.options;
    const selectedCityIds = [];
    for (let i = 0; i < opts.length; i++) {
      if (opts[i].selected) {
        selectedCityIds.push(opts[i].value);
      }
    }
    setSelectedRoute((prev) => ({ ...prev, cityIds: selectedCityIds }));
  };

  const handleSaveRouteChanges = async () => {
    alert('Implement add/update route doc logic here');
  };

  const handleEditRoute = (route) => {
    setSelectedRoute(route);
  };

  const handleDeleteRoute = async (id) => {
    alert(`Implement delete route doc for ${id}`);
  };

  // For displaying route names in the table
  const getAssociatedRoutes = (journey) => {
    if (Array.isArray(journey.routeIds)) {
      return journey.routeIds
        .map((rid) => {
          const rt = routes.find((r) => r.id === rid);
          return rt ? rt.name : 'Unknown Route';
        })
        .join(', ');
    }
    return 'No Routes';
  };

  if (loading) {
    return <div>Loading Journeys, Routes, and Users...</div>;
  }

  return (
    <div>
      <h2>Manage Journeys</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Journeys subcomponent */}
      <AdminJourneyJourneys
        journeys={journeys}
        selectedJourney={selectedJourney}
        handleJourneyInputChange={handleJourneyInputChange}
        handleSaveJourneyChanges={handleSaveJourneyChanges}
        handleEditJourney={handleEditJourney}
        handleDeleteJourney={handleDeleteJourney}
        routes={routes}
        getAssociatedRoutes={getAssociatedRoutes}
        users={users}
      />

      {/* Routes subcomponent */}
      <AdminJourneyRoutes
        routes={routes}
        selectedRoute={selectedRoute}
        handleRouteInputChange={handleRouteInputChange}
        handleRouteCitySelection={handleRouteCitySelection}
        handleSaveRouteChanges={handleSaveRouteChanges}
        handleEditRoute={handleEditRoute}
        handleDeleteRoute={handleDeleteRoute}
        cities={[]} // if needed
        getAssociatedCities={() => {}}
      />
    </div>
  );
}

export default AdminJourneysPage;
