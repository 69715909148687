// src/components/JourneyPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Journey from '../Journey'; // or wherever your Journey.js is located

function JourneyPage({ journeyData: propJourneyData, journeyId: propJourneyId }) {
  const { journeyId: paramId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // Local state for activation code (if you still want to track it)
  const [activationCode, setActivationCode] = useState('');

  // We'll store whichever journeyId we have: prop, param, or null
  const [localJourneyId, setLocalJourneyId] = useState(propJourneyId || paramId || null);
  const [localJourneyData, setLocalJourneyData] = useState(propJourneyData || null);

  const [routeNames, setRouteNames] = useState([]);

  // If there's a code passed in location.state, store it
  useEffect(() => {
    if (location.state && location.state.code) {
      setActivationCode(location.state.code);
    }
  }, [location.state]);

  // =============== FETCH FROM BACKEND (Optional) ===============
  useEffect(() => {
    // If we don't have prop data but do have a journeyId, try fetching from your backend
    if (!propJourneyData && localJourneyId) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/journey/${localJourneyId}`)
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Server error: ${res.statusText}`);
          }
          return res.json();
        })
        .then((data) => {
          setLocalJourneyData(data);
        })
        .catch((error) => {
          console.error('Error fetching journey data:', error);
          setLocalJourneyData(null);
        });
    }
  }, [propJourneyData, localJourneyId]);

  // If the parent props changed, update local state
  useEffect(() => {
    if (propJourneyData && propJourneyId) {
      setLocalJourneyData(propJourneyData);
      setLocalJourneyId(propJourneyId);
    }
  }, [propJourneyData, propJourneyId]);

  // Show a loading fallback if we haven't loaded journeyData yet
  if (!localJourneyId || !localJourneyData) {
    return (
      <div style={{ color: 'white', textAlign: 'center', marginTop: '50px' }}>
        <p>Loading journey details...</p>
        <button
          onClick={() => navigate('/')}
          style={{
            marginTop: '20px',
            padding: '10px 15px',
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Back
        </button>
      </div>
    );
  }

  // If we do have journeyData, render the actual Journey
  return (
    <div style={{ padding: '10px' }}>
      {activationCode && (
        <p style={{ color: 'white' }}>Your Activation Code: {activationCode}</p>
      )}

      <Journey
        journeyData={localJourneyData}
        journeyId={localJourneyId}
        routeNames={routeNames}
        setRouteNames={setRouteNames}
      />
    </div>
  );
}

export default JourneyPage;
