// src/admin/AdminJourneyHostels.js
import React, { useState, useEffect } from 'react';
import HostelPreview from '../admin/HostelPreview';

// Utility to parse the filename from a Firebase Storage URL
function getFileNameFromUrl(url) {
  try {
    const decodedUrl = decodeURIComponent(url);
    // e.g. ".../o/hostelImages%2F<hostelId>%2FmyFile.jpg?..."
    // We want the last part after the final "/"
    const match = decodedUrl.match(/([^/]+)\?*/);
    if (match && match[1]) {
      // match[1] might be "myFile.jpg?..."
      // Let's remove any query params if present
      return match[1].split('?')[0];
    }
    return 'unknown_file';
  } catch {
    return 'unknown_file';
  }
}

function AdminJourneyHostels({
  hostels,
  selectedHostel,
  handleHostelInputChange,
  handleEditHostel,
  handleDeleteHostel,
  cities,
  // Updated to accept an override param
  handleSaveHostelChanges,
  handleUploadHostelImage,
  handleDeleteHostelImage,
}) {
  // The single source of truth for all images (existing + new)
  // Each item = { isNew: bool, url?: string, file?: File, name: string, selected: bool }
  const [imageList, setImageList] = useState([]);

  // For any upload errors
  const [uploadErrors, setUploadErrors] = useState([]);

  // Preview modal
  const [showHostelPreview, setShowHostelPreview] = useState(false);

  // ============== Load or Reset Image List When Hostel Changes ==============
  useEffect(() => {
    if (!selectedHostel?.id) {
      // If there's no hostel selected or it's brand new (no ID),
      // just reset imageList to empty (no existing images)
      setImageList([]);
      return;
    }

    // Convert existing Firestore images into the imageList format
    const existing = (selectedHostel.images || []).map((url) => ({
      isNew: false,
      url,
      file: null,
      name: getFileNameFromUrl(url),
      selected: true, // existing images are always "selected"
    }));

    setImageList(existing);
  }, [selectedHostel]);

  // A small helper to show a warning icon if an existing image URL is broken
  const ImageValidator = ({ url }) => {
    const [isValidImage, setIsValidImage] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setIsValidImage(true);
        setLoading(false);
      };
      img.onerror = () => {
        setIsValidImage(false);
        setLoading(false);
      };
      img.src = url;
    }, [url]);

    if (loading) return <span>Checking...</span>;
    if (!isValidImage) {
      return (
        <span
          style={{
            color: 'red',
            marginRight: '10px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
          title="This image may not load correctly"
        >
          ⚠️
        </span>
      );
    }
    return null;
  };

  // ============== Handle File Selection ==============
  const handleHostelImageFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length === 0) return;

    // Convert each File into our imageList format
    const newFileObjs = selectedFiles.map((file) => ({
      isNew: true,
      file,
      url: null,
      name: file.name,
      selected: true, // by default, newly selected images are chosen for upload
    }));

    // Add them to the existing imageList
    setImageList((prev) => [...prev, ...newFileObjs]);
  };

  // ============== Remove a New File (user changes mind) ==============
  const handleRemoveNewImage = (index) => {
    setImageList((prev) => prev.filter((_, i) => i !== index));
  };

  // ============== Toggle "selected" for new images ==============
  const handleToggleSelectNewImage = (index) => {
    setImageList((prev) =>
      prev.map((img, i) =>
        i === index ? { ...img, selected: !img.selected } : img
      )
    );
  };

  // ============== Reorder Images (Up / Down) ==============
  const handleMoveImage = (index, direction) => {
    setImageList((prev) => {
      const newList = [...prev];
      const targetIndex = direction === 'up' ? index - 1 : index + 1;
      // Boundary checks
      if (targetIndex < 0 || targetIndex >= newList.length) return newList;
      // Swap
      const temp = newList[index];
      newList[index] = newList[targetIndex];
      newList[targetIndex] = temp;
      return newList;
    });
  };

  // ============== Delete an Existing Image from Firestore ==============
  const handleRemoveExistingImage = async (hostelId, url, index) => {
    if (!window.confirm('Are you sure you want to delete this image?')) return;
    try {
      await handleDeleteHostelImage(hostelId, url);
      setImageList((prev) => prev.filter((_, i) => i !== index));
    } catch (err) {
      alert(`Failed to delete image: ${err.message}`);
    }
  };

  // ============== Save All Changes in One Go ==============
  const handleSaveAllHostelData = async () => {
    setUploadErrors([]);

    // Basic validation
    if (!selectedHostel.name || !selectedHostel.cityId) {
      alert('Hostel name and city are required.');
      return;
    }

    // If no ID yet, we can't upload images to a path referencing this hostel ID
    if (!selectedHostel.id) {
      alert('Please click "Add Hostel" first to create a new hostel, then manage images.');
      return;
    }

    // We'll build a final array of image URLs
    let finalURLs = [];

    // Step 1: Upload any new images that are selected
    const newImagesToUpload = imageList.filter((img) => img.isNew && img.selected);
    let uploadedURLs = [];

    for (const img of newImagesToUpload) {
      try {
        // If there's an existing image with the same file name, we'll remove it after successful upload
        const url = await handleUploadHostelImage(img.file);
        uploadedURLs.push({ name: img.name, url });
      } catch (err) {
        console.error('Upload error:', err);
        setUploadErrors((prev) => [
          ...prev,
          `Failed to upload ${img.name}: ${err.message}`,
        ]);
      }
    }

    // Step 2: Remove duplicates from Firestore
// Remove "url" from the destructuring, since we never actually use it
for (const { name } of uploadedURLs) {
  const duplicateIndex = imageList.findIndex(
    (ex) => !ex.isNew && getFileNameFromUrl(ex.url) === name
  );
  if (duplicateIndex !== -1) {
    const duplicateItem = imageList[duplicateIndex];
    try {
      await handleDeleteHostelImage(selectedHostel.id, duplicateItem.url);
    } catch (err) {
      console.warn('Failed to remove duplicate existing image:', err);
    }
    setImageList((prev) => prev.filter((_, i) => i !== duplicateIndex));
  }
}


    // Step 3: Build the final array in the *current order*, substituting uploaded URLs for new images
    finalURLs = imageList.map((img) => {
      if (img.isNew) {
        // Find the uploaded URL (if any)
        const match = uploadedURLs.find((u) => u.name === img.name);
        return match ? match.url : null; // if not uploaded, it's null
      } else {
        return img.url;
      }
    });

    // Filter out any new images that were not selected or failed to upload
    finalURLs = finalURLs.filter((url) => url !== null);

    // Step 4: Build an updated hostel object with the final array
    const updatedHostel = { ...selectedHostel, images: finalURLs };

    // Optionally update local state so the UI shows the new images
    handleEditHostel(updatedHostel);

    // Step 5: Persist changes to Firestore by passing updatedHostel directly
    try {
      await handleSaveHostelChanges(updatedHostel);

      // Show success/failure
      const totalNew = newImagesToUpload.length;
      const totalFailed = uploadErrors.length;
      if (totalFailed > 0 && totalFailed < totalNew) {
        alert('Some images failed to upload, but others succeeded. Hostel updated.');
      } else if (totalFailed === totalNew && totalNew > 0) {
        alert('All new images failed to upload, but hostel info was still updated.');
      } else {
        alert('Hostel updated successfully!');
      }
    } catch (err) {
      alert(`Failed to save hostel changes: ${err.message}`);
      console.error('Error saving hostel:', err);
    }
  };

  // For the modal heading
  const cityObj = cities.find((c) => c.id === selectedHostel.cityId);
  const cityName = cityObj ? cityObj.name : 'Not specified';

  return (
    <div style={{ marginBottom: '30px' }}>
      <h3>Manage Hostels</h3>

      {/* ============== HOSTEL FORM ============== */}
      <div>
        <label>
          Hostel Name:
          <input
            type="text"
            name="name"
            value={selectedHostel.name}
            onChange={handleHostelInputChange}
          />
        </label>
        <label>
          City:
          <select
            name="cityId"
            value={selectedHostel.cityId}
            onChange={handleHostelInputChange}
          >
            <option value="">Select City</option>
            {cities.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Link:
          <input
            type="text"
            name="link"
            value={selectedHostel.link}
            onChange={handleHostelInputChange}
          />
        </label>
        <label>
          Notes:
          <input
            type="text"
            name="notes"
            value={selectedHostel.notes}
            onChange={handleHostelInputChange}
          />
        </label>
        <label>
          Contact:
          <input
            type="text"
            name="contact"
            value={selectedHostel.contact}
            onChange={handleHostelInputChange}
          />
        </label>
      </div>

      {/* ============== IMAGES SECTION ============== */}
      <div style={{ margin: '10px 0' }}>
        <h4>Hostel Images</h4>
        <p>
          Total Images (Existing + New): <strong>{imageList.length}</strong>
        </p>

        {/* Upload errors */}
        {uploadErrors.length > 0 && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            {uploadErrors.map((err, i) => (
              <p key={i}>{err}</p>
            ))}
          </div>
        )}

        {/* File input for new images */}
        <div style={{ marginBottom: '10px' }}>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleHostelImageFileChange}
          />
        </div>

        {/* Combined list of existing + new images */}
        <div style={{ border: '1px solid #ddd', padding: '8px', marginBottom: '10px' }}>
          <h5>Preview / Manage Images (in current order):</h5>
          {imageList.length === 0 ? (
            <p style={{ color: 'gray' }}>No images yet.</p>
          ) : (
            imageList.map((img, idx) => {
              const isNew = img.isNew;
              const displayUrl = isNew
                ? URL.createObjectURL(img.file)
                : img.url;

              return (
                <div
                  key={`${img.name}-${idx}`}
                  style={{
                    marginBottom: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  {/* Reorder Buttons */}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <button
                      onClick={() => handleMoveImage(idx, 'up')}
                      style={{
                        padding: '2px 6px',
                        marginBottom: '2px',
                        cursor: 'pointer',
                      }}
                    >
                      ↑
                    </button>
                    <button
                      onClick={() => handleMoveImage(idx, 'down')}
                      style={{
                        padding: '2px 6px',
                        cursor: 'pointer',
                      }}
                    >
                      ↓
                    </button>
                  </div>

                  {/* Image Display / Validation */}
                  {isNew ? (
                    <img
                      src={displayUrl}
                      alt="New Upload Preview"
                      style={{ maxWidth: '80px', maxHeight: '80px' }}
                    />
                  ) : (
                    <>
                      <ImageValidator url={displayUrl} />
                      <img
                        src={displayUrl}
                        alt="Existing Hostel"
                        style={{ maxWidth: '80px', maxHeight: '80px' }}
                      />
                    </>
                  )}

                  {/* File/URL Name */}
                  <span
                    style={{
                      maxWidth: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {img.name}
                  </span>

                  {/* For existing images, a "Delete" button */}
                  {!isNew && (
                    <button
                      style={{
                        backgroundColor: '#cc0000',
                        color: '#fff',
                        border: 'none',
                        padding: '5px 10px',
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        handleRemoveExistingImage(selectedHostel.id, img.url, idx)
                      }
                    >
                      Delete
                    </button>
                  )}

                  {/* For new images, let the admin remove or toggle upload */}
                  {isNew && (
                    <>
                      <button
                        style={{
                          backgroundColor: '#cc0000',
                          color: '#fff',
                          border: 'none',
                          padding: '5px 10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleRemoveNewImage(idx)}
                      >
                        Remove
                      </button>
                      <label
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={img.selected}
                          onChange={() => handleToggleSelectNewImage(idx)}
                        />
                        Upload
                      </label>
                    </>
                  )}
                </div>
              );
            })
          )}
        </div>

        {/* Save button (uploads new images, merges final array, updates Firestore) */}
        <button
          onClick={handleSaveAllHostelData}
          style={{
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            cursor: 'pointer',
            marginRight: '10px',
          }}
        >
          {selectedHostel.id ? 'Save Hostel' : 'Add Hostel'}
        </button>
      </div>

      {/* ============== PREVIEW MODAL ============== */}
      {selectedHostel.id && (
        <button
          onClick={() => setShowHostelPreview(true)}
          style={{
            backgroundColor: '#853a38',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            cursor: 'pointer',
            marginBottom: '20px',
          }}
        >
          Preview with Current Images
        </button>
      )}

      {showHostelPreview && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.4)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              maxHeight: '80vh',
              overflowY: 'auto',
              width: '90%',
              maxWidth: '400px',
              textAlign: 'center',
            }}
          >
            <h3>
              Preview: {selectedHostel.name} in {cityName}
            </h3>
            <HostelPreview
              hostelName={selectedHostel.name}
              cityName={cityName}
              contact={selectedHostel.contact}
              notes={selectedHostel.notes}
              link={selectedHostel.link}
              existingImages={
                // Show the final "display" order:
                imageList
                  .map((img) =>
                    img.isNew && img.selected
                      ? URL.createObjectURL(img.file)
                      : img.url || null
                  )
                  .filter(Boolean)
              }
              newFiles={[]} // we’re combining them above
            />

            <button
              onClick={() => setShowHostelPreview(false)}
              style={{
                marginTop: '20px',
                backgroundColor: '#853a38',
                color: 'white',
                border: 'none',
                padding: '8px 12px',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Close Preview
            </button>
          </div>
        </div>
      )}

      {/* ============== LIST OF ALL HOSTELS ============== */}
      <h4>Current Hostels</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>City</th>
            <th>Link</th>
            <th>Notes</th>
            <th>Contact</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {hostels.map((h) => {
            const cityObj = cities.find((c) => c.id === h.cityId);
            return (
              <tr key={h.id}>
                <td>{h.name}</td>
                <td>{cityObj ? cityObj.name : 'Unknown City'}</td>
                <td>
                  <a href={h.link} target="_blank" rel="noopener noreferrer">
                    Link
                  </a>
                </td>
                <td>{h.notes}</td>
                <td>{h.contact}</td>
                <td>
                  <button onClick={() => handleEditHostel(h)}>Edit</button>
                  <button onClick={() => handleDeleteHostel(h.id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminJourneyHostels;
