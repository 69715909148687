// src/admin/AdminRoute.js (or src/components/AdminRoute.js)
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// The admin emails you want to allow
const ADMIN_EMAILS = [
  'Matthew@backpass.co',
  'mgriff7@gmail.com',
  'mhgbackpass@gmail.com',
  'mhgcommercial@gmail.com'
];

function AdminRoute({ children }) {
  const { currentUser } = useAuth();

  // If no user, or user’s email not in admin list, redirect to "/"
  if (!currentUser || !ADMIN_EMAILS.includes(currentUser.email)) {
    return <Navigate to="/" replace />;
  }

  // Otherwise, render the protected component
  return children;
}

export default AdminRoute;
