// src/404.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div
      style={{
        backgroundColor: 'transparent', // Make the container transparent
        minHeight: '100vh',
        textAlign: 'center',
        padding: '50px 0',
      }}
    >
      <h1 style={{ color: '#ffffff' }}>404 - Page Not Found</h1>
      <p style={{ color: '#ffffff' }}>
        Oops! The page you are looking for does not exist.
      </p>
      <Link
        to="/"
        style={{
          display: 'inline-block',
          backgroundColor: '#853a38',
          color: '#ffffff',
          padding: '10px 20px',
          textDecoration: 'none',
          fontSize: '18px',
          borderRadius: '4px',
          marginTop: '20px',
        }}
      >
        Go Back to Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
