// src/Journey.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';

import { db, auth } from './firebase';
import DaySlot from './components/DaySlot';
import Hostel from './components/Hostel';

function Journey({
  journeyData: propJourneyData,
  journeyId: propJourneyId,
  setRouteNames,
  routeNames,
}) {
  // Local states
  const [journeyData, setJourneyData] = useState(propJourneyData);
  const [myJourneyId, setMyJourneyId] = useState(propJourneyId);

  const [hostelAssignments, setHostelAssignments] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);

  const [availableCities, setAvailableCities] = useState([]);
  const [availableHostels, setAvailableHostels] = useState([]);

  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [isHostelModalOpen, setIsHostelModalOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);

  const navigate = useNavigate();

  // Handle "Back" button
  const handleBackClick = () => {
    navigate('/dashboard');
  };

// ============= CHECK AUTH & OWNERSHIP =============
useEffect(() => {
  (async () => {
    // 1) Must be logged in
    const currentUser = auth.currentUser;
    if (!currentUser) {
      alert('Please sign in to access this journey.');
      navigate('/signin');
      return;
    }

    // 2) Check if user doc has this journey in journeys[]
    const emailLower = currentUser.email.toLowerCase();
    const userRef = doc(db, 'users', emailLower);
    const userSnap = await getDoc(userRef);
    if (!userSnap.exists()) {
      alert('User doc not found. Please complete your info first.');
      navigate('/user-info');
      return;
    }

    const userData = userSnap.data();
    if (!userData.journeys || !userData.journeys.includes(propJourneyId)) {
      alert('You do not have access to this journey. Please link the code first.');
      navigate('/dashboard');
      return;
    }

    // 3) The user DOES own this journey, so let's fetch the doc
    const journeyRef = doc(db, 'journeys', propJourneyId);
    const journeySnap = await getDoc(journeyRef);
    if (!journeySnap.exists()) {
      alert('Journey doc not found. Contact support.');
      navigate('/dashboard');
      return;
    }

    const freshData = journeySnap.data();

    // **** NEW Payment Check ****
    if (!freshData.paymentStatus) {
      alert('This journey is not paid. Redirecting you to the payment page...');
      navigate('/payment', { state: { journeyId: propJourneyId, journeyData: freshData } });
      return;
    }
    // ***************************

    // 4) Save data to state
    setJourneyData(freshData);
    setMyJourneyId(propJourneyId);
  })();
}, [propJourneyId, navigate]);

  // ============= INIT HOSTEL ASSIGNMENTS =============
  const initializeHostelAssignments = useCallback(
    async (freshJourneyData) => {
      if (!freshJourneyData) return;

      try {
        const { days: freshDays } = freshJourneyData;
        const journeyRef = doc(db, 'journeys', propJourneyId);
        const journeySnap = await getDoc(journeyRef);

        if (journeySnap.exists()) {
          const data = journeySnap.data();
          setHostelAssignments(data.hostelAssignments || Array(freshDays).fill(null));
        } else {
          setHostelAssignments(Array(freshDays).fill(null));
        }
      } catch (error) {
        console.error('Error initializing hostel assignments:', error);
      }
    },
    [propJourneyId]
  );

  // ============= FETCH ROUTE NAMES =============
  const fetchRouteNames = useCallback(
    async (freshJourneyData) => {
      if (!freshJourneyData) return;
      const { routeIds } = freshJourneyData;

      try {
        const routesRef = collection(db, 'routes');
        const routesQuery = query(routesRef, where('__name__', 'in', routeIds || []));
        const routesSnapshot = await getDocs(routesQuery);

        const routeList = routesSnapshot.docs.map((doc) => doc.data().name);
        setRouteNames(routeList);
      } catch (error) {
        console.error('Error fetching route names:', error);
      }
    },
    [setRouteNames]
  );

  // ============= WHEN journeyData CHANGES, INIT =============
  useEffect(() => {
    if (!journeyData) return;
    initializeHostelAssignments(journeyData);
    fetchRouteNames(journeyData);
  }, [journeyData, initializeHostelAssignments, fetchRouteNames]);

  // ============= OPEN CITY MODAL =============
  const openModal = async (dayIndex) => {
    setSelectedDay(dayIndex);
    await fetchAvailableCities();
    setIsCityModalOpen(true);
  };

  // ============= FETCH AVAILABLE CITIES =============
  const fetchAvailableCities = async () => {
    try {
      if (!journeyData || !journeyData.routeIds) {
        setAvailableCities([]);
        return;
      }
      const { routeIds } = journeyData;

      const routesRef = collection(db, 'routes');
      const routesQuery = query(routesRef, where('__name__', 'in', routeIds || []));
      const routesSnapshot = await getDocs(routesQuery);

      const cityIdsSet = new Set();
      routesSnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (Array.isArray(data.cityIds)) {
          data.cityIds.forEach((cityId) => cityIdsSet.add(cityId));
        }
      });

      const cityIds = Array.from(cityIdsSet);
      if (cityIds.length === 0) {
        setAvailableCities([]);
        return;
      }

      const citiesRef = collection(db, 'cities');
      const citiesQuery = query(citiesRef, where('__name__', 'in', cityIds));
      const citiesSnapshot = await getDocs(citiesQuery);

      const cities = citiesSnapshot.docs.map((c) => ({
        id: c.id,
        ...c.data(),
      }));
      setAvailableCities(cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  // ============= FETCH AVAILABLE HOSTELS =============
  const fetchAvailableHostels = async (cityId) => {
    try {
      const hostelsRef = collection(db, 'hostels');
      const hostelsQuery = query(hostelsRef, where('cityId', '==', cityId));
      const hostelsSnapshot = await getDocs(hostelsQuery);

      const hostels = hostelsSnapshot.docs.map((h) => ({
        id: h.id,
        ...h.data(),
      }));

      setAvailableHostels(hostels);
    } catch (error) {
      console.error('Error fetching hostels:', error);
    }
  };

  // ============= SELECT A HOSTEL =============
  const handleHostelSelection = async (hostel) => {
    try {
      const hostelWithCity = {
        ...hostel,
        cityName: selectedCity?.name,
      };
      const newAssignments = [...hostelAssignments];
      newAssignments[selectedDay] = hostelWithCity;
      setHostelAssignments(newAssignments);

      // Update Firestore
      if (myJourneyId) {
        const journeyRef = doc(db, 'journeys', myJourneyId);
        await updateDoc(journeyRef, {
          hostelAssignments: newAssignments,
        });
      }
      setIsHostelModalOpen(false);
    } catch (error) {
      console.error('Error saving hostel selection:', error);
    }
  };

  // ============= DESELECT HOSTEL =============
  const handleDeselectHostel = async (dayIndex) => {
    try {
      const newAssignments = [...hostelAssignments];
      newAssignments[dayIndex] = null;
      setHostelAssignments(newAssignments);

      if (myJourneyId) {
        const journeyRef = doc(db, 'journeys', myJourneyId);
        await updateDoc(journeyRef, {
          hostelAssignments: newAssignments,
        });
      }
    } catch (error) {
      console.error('Error deselecting hostel:', error);
    }
  };

  // ============= DATE FORMATTING =============
  const calculateDate = (offset) => {
    if (!journeyData?.startDate) return `Day ${offset + 1}`;
    const date = new Date(journeyData.startDate);
    date.setDate(date.getDate() + offset);
    const monthDay = date.toLocaleDateString(undefined, { month: 'long', day: 'numeric' });
    const weekday = date.toLocaleDateString(undefined, { weekday: 'long' });
    return `${monthDay} (${weekday})`;
  };

  // ============= BOOK REQUESTS =============
  const handleBookRequests = async () => {
    if (!myJourneyId || !journeyData || !hostelAssignments) {
      alert('Journey data is not fully loaded. Please try again soon.');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/send-journey-email`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            journeyId: myJourneyId,
            journeyData,
            hostelAssignments,
          }),
        }
      );

      const responseText = await response.text();
      console.log('Response Text:', responseText);

      let parsed;
      try {
        parsed = JSON.parse(responseText);
      } catch (error) {
        parsed = { error: responseText };
      }

      if (response.ok) {
        alert(
          'Journey saved and email sent successfully. You will receive updates once bookings are made. For questions, contact BackPass at +1-214-315-6968.'
        );
      } else {
        alert(`Failed to send email: ${parsed.error || responseText}`);
      }
    } catch (error) {
      console.error('Error sending journey email:', error);
      alert('An error occurred while sending the email. Please try again.');
    }
  };

  // ============= RENDER =============
  if (!journeyData || !myJourneyId) {
    return <p style={{ color: 'white' }}>Loading journey details...</p>;
  }

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: 'rgba(255,255,255,0.0)',
        borderRadius: '0px',
      }}
    >
      <h2>Route: {routeNames.join(', ')}</h2>

      <button
        onClick={handleBackClick}
        style={{
          marginTop: '20px',
          marginBottom: '20px',
          padding: '10px 15px',
          backgroundColor: '#853a38',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Back
      </button>

      {/* List each DaySlot */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {Array.from({ length: journeyData.days }).map((_, dayIndex) => (
          <DaySlot
            key={dayIndex}
            dayLabel={calculateDate(dayIndex)}
            assignedHostel={hostelAssignments[dayIndex]}
            onClick={() => openModal(dayIndex)}
            onDeselect={() => handleDeselectHostel(dayIndex)}
          />
        ))}
      </div>

      <button
        onClick={handleBookRequests}
        style={{
          marginTop: '20px',
          padding: '10px 15px',
          backgroundColor: 'green',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        Press Me to Have BackPass Book Stuff
      </button>

      {/* ========== City Selection Modal ========== */}
      {isCityModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setIsCityModalOpen(false)}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '300px',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Select a City for {calculateDate(selectedDay)}</h3>
            {availableCities.map((city) => (
              <div key={city.id} style={{ marginBottom: '10px' }}>
                <button
                  onClick={async () => {
                    setSelectedCity(city);
                    await fetchAvailableHostels(city.id);
                    setIsCityModalOpen(false);
                    setIsHostelModalOpen(true);
                  }}
                  style={{
                    width: '100%',
                    padding: '10px',
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  {city.name}
                </button>
              </div>
            ))}
            <button
              onClick={() => setIsCityModalOpen(false)}
              style={{
                marginTop: '20px',
                padding: '10px 15px',
                backgroundColor: '#853a38',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* ========== Hostel Selection Modal ========== */}
      {isHostelModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setIsHostelModalOpen(false)}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              width: '300px',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>
              Select a Hostel in {selectedCity?.name} for {calculateDate(selectedDay)}
            </h3>

            {availableHostels.map((hostel) => (
              <div
                key={hostel.id}
                style={{
                  border: '1px solid #ddd',
                  padding: '10px',
                  marginBottom: '10px',
                  borderRadius: '5px',
                }}
              >
                <Hostel hostelId={hostel.id} />
                <button
                  onClick={() => handleHostelSelection(hostel)}
                  style={{
                    marginTop: '10px',
                    backgroundColor: '#853a38',
                    color: 'white',
                    padding: '5px 10px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Select
                </button>
              </div>
            ))}

            <button
              onClick={() => setIsHostelModalOpen(false)}
              style={{
                marginTop: '20px',
                padding: '10px 15px',
                backgroundColor: '#853a38',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Journey;
