// src/AuthService.js
import { auth, db } from './firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  signOut,
} from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';

/**
 * Register a new user with email & password, then create Firestore doc using email as the doc ID.
 */
export async function registerWithEmail(email, password, extraData = {}) {
  // 1) Create user in Firebase Auth
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);

  // 2) Optionally send verification email
  await sendEmailVerification(userCredential.user);

  // 3) Create user doc if brand new, passing along firstName/phone
  await createUserDocIfNotExists(userCredential.user, extraData);

  return userCredential;
}

/**
 * Log in an existing user with email & password.
 */
export async function loginWithEmail(email, password) {
  return await signInWithEmailAndPassword(auth, email, password);
}

/**
 * Log out current user
 */
export async function logout() {
  return await signOut(auth);
}

/**
 * Listen for Auth State changes (login/logout)
 */
export function onUserStateChanged(callback) {
  return onAuthStateChanged(auth, callback);
}

/**
 * Create a Firestore doc if it doesn't already exist, keyed by user.email
 * extraData might include firstName, phoneNumber, etc.
 */
export async function createUserDocIfNotExists(user, extraData = {}) {
  if (!user) return;

  // Convert email to lowercase for consistency
  const emailLower = user.email?.toLowerCase();
  if (!emailLower) {
    console.log('No user.email found. Not creating doc.');
    return;
  }

  const userRef = doc(db, 'users', emailLower);
  const snap = await getDoc(userRef);

  if (!snap.exists()) {
    // Merge any extra fields with defaults
    const newUserData = {
      uid: user.uid,
      email: user.email || '',
      phoneNumber: user.phoneNumber || extraData.phoneNumber || '',
      firstName: extraData.firstName || '',
      createdAt: new Date(),
      role: 'user',
      journeys: [],
    };

    await setDoc(userRef, newUserData);
    console.log('✅ Created new user doc at ID:', emailLower);
  } else {
    console.log('User doc already exists for:', emailLower);
  }
}
