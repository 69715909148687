import React from 'react';

function AdminJourneyRoutes({
  routes,
  selectedRoute,
  handleRouteInputChange,
  handleRouteCitySelection,
  handleSaveRouteChanges,
  handleEditRoute,
  handleDeleteRoute,
  cities,
  getAssociatedCities,
}) {
  return (
    <div style={{ marginBottom: '30px' }}>
      <h3>Manage Routes</h3>
      <div>
        <label>
          Route Name:
          <input
            type="text"
            name="name"
            value={selectedRoute.name}
            onChange={handleRouteInputChange}
          />
        </label>
        <label>
          Cities:
          <select
            name="cityIds"
            value={selectedRoute.cityIds}
            onChange={handleRouteCitySelection}
            multiple
          >
            {cities.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Notes:
          <input
            type="text"
            name="notes"
            value={selectedRoute.notes}
            onChange={handleRouteInputChange}
          />
        </label>
        <button onClick={handleSaveRouteChanges}>
          {selectedRoute.id ? 'Update Route' : 'Add Route'}
        </button>
      </div>

      <h4>Current Routes</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Cities</th>
            <th>Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {routes.map((rt) => {
            const associatedCities = getAssociatedCities(rt);
            return (
              <tr key={rt.id}>
                <td>{rt.name}</td>
                <td>{associatedCities}</td>
                <td>{rt.notes}</td>
                <td>
                  <button onClick={() => handleEditRoute(rt)}>Edit</button>
                  <button onClick={() => handleDeleteRoute(rt.id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminJourneyRoutes;
