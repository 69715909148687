import React from 'react';

function AdminJourneyUsers({
  users,
  selectedUser,
  setSelectedUser,
  handleUserInputChange,
  handleSaveUser,
  handleEditUser,
  handleDeleteUser,
}) {
  return (
    <div style={{ marginBottom: '30px' }}>
      <h3>Manage Users</h3>

      <div>
        <label>
          First Name:
          <input
            type="text"
            name="firstName"
            value={selectedUser.firstName}
            onChange={handleUserInputChange}
          />
        </label>
        <label>
          Last Name:
          <input
            type="text"
            name="lastName"
            value={selectedUser.lastName}
            onChange={handleUserInputChange}
          />
        </label>

        {/* NEW Gender Field */}
        <label>
          Gender:
          <select
            name="gender"
            value={selectedUser.gender}
            onChange={handleUserInputChange}
          >
            <option value="">(Select)</option>
            <option value="man">Man</option>
            <option value="woman">Woman</option>
          </select>
        </label>

        <label>
          Email:
          <input
            type="email"
            name="email"
            value={selectedUser.email}
            onChange={handleUserInputChange}
          />
        </label>
        <label>
          Phone:
          <input
            type="text"
            name="phoneNumber"
            value={selectedUser.phoneNumber}
            onChange={handleUserInputChange}
          />
        </label>
        <label>
          Nationality:
          <input
            type="text"
            name="nationality"
            value={selectedUser.nationality}
            onChange={handleUserInputChange}
          />
        </label>
        <label>
          Address:
          <input
            type="text"
            name="address"
            value={selectedUser.address}
            onChange={handleUserInputChange}
          />
        </label>
        <label>
          Passport ID:
          <input
            type="text"
            name="passportId"
            value={selectedUser.passportId}
            onChange={handleUserInputChange}
          />
        </label>
        <label>
          Passport URL:
          <input
            type="text"
            name="passportURL"
            value={selectedUser.passportURL}
            onChange={handleUserInputChange}
          />
        </label>

        <button onClick={handleSaveUser}>
          {selectedUser.id ? 'Update User' : 'Add User'}
        </button>
      </div>

      <h4>Current Users</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Gender</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Nationality</th>
            <th>Address</th>
            <th>Passport ID</th>
            <th>Passport URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={u.id}>
              <td>
                {u.firstName} {u.lastName}
              </td>
              <td>{u.gender || ''}</td>
              <td>{u.email}</td>
              <td>{u.phoneNumber}</td>
              <td>{u.nationality}</td>
              <td>{u.address}</td>
              <td>{u.passportId}</td>
              <td>
                {u.passportURL ? (
                  <a href={u.passportURL} target="_blank" rel="noreferrer">
                    View
                  </a>
                ) : (
                  'None'
                )}
              </td>
              <td>
                <button onClick={() => handleEditUser(u)}>Edit</button>
                <button onClick={() => handleDeleteUser(u.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminJourneyUsers;
