import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

import AdminJourneyHostels from './AdminJourneyHostels';
import AdminJourneyCities from './AdminJourneyCities';

function AdminHostelsPage() {
  const [hostels, setHostels] = useState([]);
  const [selectedHostel, setSelectedHostel] = useState({
    id: '',
    name: '',
    cityId: '',
    link: '',
    notes: '',
    contact: '',
    images: [],
  });

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({
    id: '',
    name: '',
    hostelIds: [],
    notes: '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      setLoading(true);
      try {
        const [hostelsSnap, citiesSnap] = await Promise.all([
          getDocs(collection(db, 'hostels')),
          getDocs(collection(db, 'cities')),
        ]);

        if (!isMounted) return;

        // Build cityMap for quick cityName lookups
        const cityMap = {};
        citiesSnap.docs.forEach((cDoc) => {
          cityMap[cDoc.id] = cDoc.data().name;
        });

        const hostelsData = hostelsSnap.docs.map((hDoc) => {
          const hData = hDoc.data();
          return {
            ...hData,
            id: hDoc.id,
            cityName: cityMap[hData.cityId] || 'Unknown City',
            images: hData.images || [],
          };
        });
        setHostels(hostelsData);

        const citiesData = citiesSnap.docs.map((cDoc) => ({
          id: cDoc.id,
          ...cDoc.data(),
        }));
        setCities(citiesData);

      } catch (err) {
        console.error('Error fetching hostels/cities:', err);
        setError('Failed to load hostels/cities');
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  // ========== Hostels Handlers (placeholder) ==========
  const handleHostelInputChange = (e) => {
    // e.g. setSelectedHostel( ... )
  };
  const handleEditHostel = (hostel) => {
    setSelectedHostel({ ...hostel, images: hostel.images || [] });
  };
  const handleDeleteHostel = async (id) => {
    // e.g. deleteDoc ...
  };
  const handleSaveHostelChanges = async (override) => {
    // e.g. addDoc or updateDoc ...
  };
  const handleUploadHostelImage = async (file) => {
    // e.g. upload file to Storage
    return 'downloadURL';
  };
  const handleDeleteHostelImage = async (hostelId, imageUrl) => {
    // e.g. remove from Storage + Firestore
  };

  // ========== Cities Handlers (placeholder) ==========
  const handleCityInputChange = (e) => {
    // ...
  };
  const handleEditCity = (city) => {
    setSelectedCity(city);
  };
  const handleDeleteCity = async (id) => {
    // ...
  };
  const handleSaveCityChanges = async () => {
    // ...
  };
  const handleCityHostelSelection = (e) => {
    // ...
  };

  const getAssociatedHostels = (city) => {
    if (!city.hostelIds) return 'No Hostels';
    return city.hostelIds
      .map((hid) => {
        const h = hostels.find((hh) => hh.id === hid);
        return h ? h.name : 'Unknown Hostel';
      })
      .join(', ');
  };

  if (loading) {
    return <div>Loading Hostels & Cities...</div>;
  }

  return (
    <div>
      <h2>Manage Hostels & Cities</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      <AdminJourneyHostels
        hostels={hostels}
        selectedHostel={selectedHostel}
        handleHostelInputChange={handleHostelInputChange}
        handleEditHostel={handleEditHostel}
        handleDeleteHostel={handleDeleteHostel}
        cities={cities}
        handleSaveHostelChanges={handleSaveHostelChanges}
        handleUploadHostelImage={handleUploadHostelImage}
        handleDeleteHostelImage={handleDeleteHostelImage}
      />

      <AdminJourneyCities
        cities={cities}
        selectedCity={selectedCity}
        handleCityInputChange={handleCityInputChange}
        handleEditCity={handleEditCity}
        handleDeleteCity={handleDeleteCity}
        hostels={hostels}
        handleSaveCityChanges={handleSaveCityChanges}
        handleCityHostelSelection={handleCityHostelSelection}
        getAssociatedHostels={getAssociatedHostels}
      />
    </div>
  );
}

export default AdminHostelsPage;
