// src/components/DaySlot.js
import React from 'react';

function DaySlot({ dayLabel, assignedHostel, onClick, onDeselect }) {
  return (
    <div
      style={{
        padding: '5px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        minHeight: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        margin: '10px',
        width: '400px',
        maxWidth: '90vw',
        borderRadius: '32px',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
      onClick={onClick}
    >
      <h3>{dayLabel}</h3>
      {assignedHostel ? (
        <>
          <p>{assignedHostel.cityName || 'City not found'}</p>
          <p>{assignedHostel.name}</p>
          <p>Contact: {assignedHostel.contact}</p>
          <p>Notes: {assignedHostel.notes}</p>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDeselect();
            }}
            style={{
              backgroundColor: '#853a38',
              color: 'white',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              marginTop: '10px',
            }}
          >
            Deselect
          </button>
        </>
      ) : (
        <p>Select Lodging</p>
      )}
    </div>
  );
}

export default DaySlot;
