// src/admin/AdminJourneyCities.js
import React from 'react';

function AdminJourneyCities({
  cities,
  selectedCity,
  handleCityInputChange,
  handleEditCity,
  handleDeleteCity,
  hostels,
  handleSaveCityChanges,
  handleCityHostelSelection,
  getAssociatedHostels,
}) {
  return (
    <div style={{ marginBottom: '30px' }}>
      <h3>Manage Cities</h3>
      <div>
        <label>
          City Name:
          <input
            type="text"
            name="name"
            value={selectedCity.name}
            onChange={handleCityInputChange}
          />
        </label>
        <label>
          Hostels:
          <select
            name="hostelIds"
            value={selectedCity.hostelIds}
            onChange={handleCityHostelSelection}
            multiple
          >
            {hostels.map((ht) => (
              <option key={ht.id} value={ht.id}>
                {ht.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Notes:
          <input
            type="text"
            name="notes"
            value={selectedCity.notes}
            onChange={handleCityInputChange}
          />
        </label>
        <button onClick={handleSaveCityChanges}>
          {selectedCity.id ? 'Update City' : 'Add City'}
        </button>
      </div>

      <h4>Current Cities</h4>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Hostels</th>
            <th>Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cities.map((cty) => {
            const associatedHostels = getAssociatedHostels(cty);
            return (
              <tr key={cty.id}>
                <td>{cty.name}</td>
                <td>{associatedHostels}</td>
                <td>{cty.notes}</td>
                <td>
                  <button onClick={() => handleEditCity(cty)}>Edit</button>
                  <button onClick={() => handleDeleteCity(cty.id)}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdminJourneyCities;
